import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS, HttpClient } from '@angular/common/http';
import { NgbNavModule, NgbAccordionModule, NgbTooltipModule, NgbModule, NgbPagination, NgbPaginationModule, NgbDatepickerModule } from '@ng-bootstrap/ng-bootstrap';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { ExtrapagesModule } from './views/extrapages/extrapages.module';
import { LayoutsModule } from './layouts/layouts.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ErrorInterceptor } from './helpers/error.interceptor';
import { JwtInterceptor } from './helpers/jwt.interceptor';
import { ToastNotificationsModule } from 'ngx-toast-notifications';
import { UIModule } from './shared/ui/ui.module';
import { ModalShipmentComponent } from './modals/modal-shipment/modal-shipment.component';
import { ModalShipmentInfoComponent } from './modals/modal-shipment-info/modal-shipment-info.component';
import { FormsModule } from '@angular/forms';
import { ModalHubComponent } from './modals/modal-hub/modal-hub.component';
import { ModalDestinationComponent } from './modals/modal-destination/modal-destination.component';
import { NgxMaskModule } from 'ngx-mask';
import { ModalQuestionsComponent } from './modals/modal-questions/modal-questions.component';
import { ModalPrintComponent } from './modals/modal-print/modal-print.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { SafeUrlPipe } from './helpers/safeurl';
import { ModalContactComponent } from './modals/modal-contact/modal-contact.component';
import { ModalSubcontactComponent } from './modals/modal-subcontact/modal-subcontact.component';
import { ModalFilterComponent } from './modals/modal-filter/modal-filter.component';
import { ModalManifestComponent } from './modals/modal-manifest/modal-manifest.component';
import { ModalManifestShipmentComponent } from './modals/modal-manifest-shipment/modal-manifest-shipment.component';
import { DateAgoPipe } from './helpers/date-ago.pipe';
import { SocketioService } from './services/socketio.service';
import { ModalCabangComponent } from './modals/modal-cabang/modal-cabang.component';
import { ModalsCityComponent } from './modals/modals-city/modals-city.component';
import { WidgetModule } from './shared/widget/widget.module';
import { QRCodeModule } from 'angularx-qrcode';
import { NgxBarcodeModule } from 'ngx-barcode';
import { ComponentSaModule } from './views_sa/components/components_sa.module';
import { ModalTracingComponent } from './modals/modal-tracing/modal-tracing.component';
import { LightboxModule } from 'ngx-lightbox';
import { ModalShipmentExportComponent } from './modals/modal-shipment-export/modal-shipment-export.component';

export function createTranslateLoader(http: HttpClient): any {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [
    AppComponent,
    ModalShipmentComponent,
    ModalShipmentInfoComponent,
    ModalHubComponent,
    ModalDestinationComponent,
    ModalQuestionsComponent,
    ModalPrintComponent,
    SafeUrlPipe,
    ModalContactComponent,
    ModalSubcontactComponent,
    ModalFilterComponent,
    ModalManifestComponent,
    ModalManifestShipmentComponent,
    ModalCabangComponent,
    DateAgoPipe,
    ModalsCityComponent,
    ModalTracingComponent,
    ModalShipmentExportComponent,
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient]
      }
    }),
    LayoutsModule,
    ExtrapagesModule,
    AppRoutingModule,
    CarouselModule,
    NgbAccordionModule,
    NgbNavModule,
    NgbPaginationModule,
    NgbTooltipModule,
    NgbDatepickerModule,
    NgxMaskModule.forRoot(),
    ToastNotificationsModule.forRoot({duration: 2000, type: 'danger', position:'top-right'}),
    NgbModule,
    UIModule,
    FormsModule,
    PdfViewerModule,
    WidgetModule,
    QRCodeModule,
    NgxBarcodeModule,
    LightboxModule,
    ComponentSaModule
  ],
  bootstrap: [AppComponent],
  entryComponents:[
        ModalShipmentComponent,
        ModalShipmentInfoComponent,
        ModalHubComponent,
        ModalQuestionsComponent,
        ModalPrintComponent,
        ModalContactComponent,
        ModalSubcontactComponent,
        ModalFilterComponent,
        ModalManifestComponent,
        ModalManifestShipmentComponent,
        ModalCabangComponent,
        ModalTracingComponent
      ],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    SocketioService
  ],
})
export class AppModule { }
