import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Toaster } from "ngx-toast-notifications";
import { ApiService } from "src/app/services/api.service";
import { GlobalConstants } from "src/app/services/global";
@Component({
  selector: 'app-master-hub',
  templateUrl: './master-hub.component.html',
  styleUrls: ['./master-hub.component.scss']
})
export class MasterHubComponent implements OnInit {
  @Output() sendHubData: EventEmitter<any> = new EventEmitter();
   global = {
    ...GlobalConstants.x,
  };
   pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };  
  public keyword = 'name';
  modelName="test";
  public historyHeading: string = 'Recently selected';
  public hub=[{
    hub_id:'xxx',
    name:"yyyyy"
  }];
  public hubdata=[];
  isLoadingResult=false;
  dataName;
  constructor(
    private apiService: ApiService,
    private toast: Toaster,
  ) {
  }
  ngOnInit() {}

  setDataHub(data){
    this.hubdata=[];
    for (let x in data) {
      this.hubdata.push({
        id:data[x].hub_id,
        name:data[x].hub_name,
      })
    }
  }
  async getHub(){
    this.isLoadingResult=true;
    try {
      const res: any  = await this.apiService.getHub(this.pgn.currentPage,this.pgn.limit,this.pgn.term);
      if(res && res.success==true){
        this.hub = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        // this.cu=res.current;
        this.setDataHub(res.data);
        this.isLoadingResult=false;
      }
      } catch (error) {
        this.isLoadingResult=false;
        this.toast.open({type:'danger',text:error})
      }
  }
  selectEvent(item) {
    // do something with selected item
    console.log('selectvet',item);
    this.modelName=item.id;
    this.dataName=item.id;
    this.sendHubData.emit(item);
  }
  searchCleared() {
    console.log('searchCleared');
    this.hub = [];
  }
  onChangeSearch(search: string) {
    // fetch remote data from here
    console.log('on search',search)
    this.pgn.term=search;
    this.getHub();
    // And reassign the 'data' which is binded to 'data' property.
  }
  onFocused(e) {
    // do something
  }
}
