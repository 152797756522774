import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ModalDestinationComponent } from 'src/app/modals/modal-destination/modal-destination.component';
import { ModalHubComponent } from 'src/app/modals/modal-hub/modal-hub.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalConstants } from 'src/app/services/global';

@Component({
  selector: 'app-master-vendor-harga',
  templateUrl: './master-vendor-harga.component.html',
  styleUrls: ['./master-vendor-harga.component.scss']
})
export class MasterVendorHargaComponent implements OnInit {
  @Input() vendorId:string;
  validateForm!: FormGroup;
  submit: boolean = false;
  global = {...GlobalConstants.x};
  typeHarga = [...GlobalConstants.typeHarga]
  modaData:any;
  serviceData:any;
  uomData:any;
  userData:any;
  hargaData:any;
  isiDataSelanjutnya:boolean=false;
  isEdit:boolean=false;
  editData:any;
  constructor(
    private fb:FormBuilder,
    private apiService: ApiService,
    private toast: Toaster,
    private modal:NgbModal,
    private auth:AuthenticationService
  ) { 
    this.validateForm = this.fb.group({
      origin: [{ value: null, disabled: true }, [Validators.required]],
      originname:[{ value: null, disabled: true }, [Validators.required]],
      destination: [{ value: null, disabled: true }, [Validators.required]],
      destinationname:[{ value: null, disabled: true }, [Validators.required]],
      moda_id:[{ value: 'A' }, [Validators.required]],
      service_id:[{ value: null}, [Validators.required]],
      uom_id:[{ value: null}, [Validators.required]],
      lead_time_from:[{value:1},[Validators.required]],
      lead_time_to:[{value:1},[Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      price_from:[{value:1},[Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      price_to:[{value:999999},[Validators.required,Validators.pattern(/^-?(0|[1-9]\d*)?$/)]],
      type_price:[{ value: null}, [Validators.required]],
      price_1:[{ value: 0}, [Validators.required]]
    })
    this.getModa();
    this.getService();
    this.getUom()
  }
  async ngOnInit() {
    let x= await this.apiService.getMe();
    if (x.success){
      this.userData=x.data;
    };
    this.clearForm();
    this.validateForm.valueChanges.subscribe(selectedValue => {
      this.cekValueAtas();
    })
  };
  cekValueAtas(){
    let x = this.validateForm.value;
    if (this.validateForm.get('origin').value
          && this.validateForm.get('destination').value
          && this.validateForm.get('uom_id').value 
          && this.validateForm.get('service_id').value
          && this.validateForm.get('lead_time_from').value
          && this.validateForm.get('lead_time_to').value
          ){
            this.isiDataSelanjutnya=true;
            this.getHargaByKey();
    }else{
      this.isiDataSelanjutnya=false;
    }
  }
  resetForm(){
    this.isEdit=false
    this.validateForm.reset();
    this.clearForm();
  }
  clearForm(){
    this.validateForm.controls['origin'].setValue(this.userData.city.dest_id);
    this.validateForm.controls['originname'].setValue(this.userData.city.dest_name);
    this.validateForm.controls['moda_id'].setValue('A');
    this.validateForm.controls['lead_time_from'].setValue(1);
    this.validateForm.controls['lead_time_to'].setValue(2);
    this.validateForm.controls['price_from'].setValue(1);
    this.validateForm.controls['price_to'].setValue(999999);
    this.validateForm.controls['price_1'].setValue(0);
    this.validateForm.controls['type_price'].setValue('flat');
    this.validateForm.controls['moda_id'].enable();
    this.validateForm.controls['uom_id'].enable();
    this.validateForm.controls['service_id'].enable();
    this.validateForm.controls['price_from'].enable();
    this.validateForm.controls['price_to'].enable();
    this.isEdit=false
  }
  fillFormData(data){
    this.isEdit=true;
    console.log('edit',data);
    this.editData={...data};
    this.validateForm.controls['origin'].setValue(data.origin);
    this.validateForm.controls['originname'].setValue(data.originname);
    this.validateForm.controls['moda_id'].setValue(data.moda_id);
    this.validateForm.controls['lead_time_from'].setValue(data.lead_time_from);
    this.validateForm.controls['lead_time_to'].setValue(data.lead_time_to);
    this.validateForm.controls['price_from'].setValue(data.price_from);
    this.validateForm.controls['price_to'].setValue(data.price_to);
    this.validateForm.controls['price_1'].setValue(data.price_1);
    this.validateForm.controls['type_price'].setValue(data.type_price);
    this.validateForm.controls['moda_id'].disable();
    this.validateForm.controls['uom_id'].disable();
    this.validateForm.controls['service_id'].disable();
    this.validateForm.controls['price_from'].disable();
    this.validateForm.controls['price_to'].disable();
  }
  async getModa(){
    try {
      const res: any  = await this.apiService.getModa();
      if(res && res.success==true){
        this.modaData = res.data;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  async getService(){
    try {
      const res: any  = await this.apiService.getService();
      if(res && res.success==true){
        this.serviceData = res.data;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  async getUom(){
    try {
      const res: any  = await this.apiService.getUom();
      if(res && res.success==true){
        this.uomData = res.data;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  openModalDest(data:string='ORIGIN'){
    const modalRef=this.modal.open(ModalDestinationComponent,{
      scrollable:true,
      size:'lg',
      keyboard:false,
      backdrop:'static'
    });
    modalRef.componentInstance.fromParent = data
    modalRef.result.then((r)=>{
        if (r){
          if (r.type==='DESTINATION'){
            this.validateForm.controls["destination"].setValue(r.data.dest_id);
          this.validateForm.controls["destinationname"].setValue(r.data.dest_name);
          }else{
            this.validateForm.controls["origin"].setValue(r.data.dest_id);
          this.validateForm.controls["originname"].setValue(r.data.dest_name);
          }
        }
    },(x)=>{
    })
  }
  get form() {
    return this.validateForm.controls;
  }
  allForm(){
    return {
      partner_id:this.vendorId,
      origin:this.validateForm.get('origin').value,
      destination:this.validateForm.get('destination').value,
      lead_time_from:this.validateForm.get('lead_time_from').value,
      lead_time_to:this.validateForm.get('lead_time_to').value,
      uom_id:this.validateForm.get('uom_id').value,
      service_id:this.validateForm.get('service_id').value,
      moda_id:this.validateForm.get('moda_id').value,
      price_from:this.validateForm.get('price_from').value,
      price_to:this.validateForm.get('price_to').value,
      type_price:this.validateForm.get('type_price').value,
      price_1:this.validateForm.get('price_1').value,
    }
  }
  async getHargaByKey(){
    let valueHarga={
      ...this.allForm()
    }
    console.log(valueHarga);
    try {
      const res: any  = await this.apiService.getContactHargaByFiveKeys('vendor',this.vendorId,valueHarga.origin,valueHarga.destination,valueHarga.uom_id,valueHarga.service_id,valueHarga.moda_id);
      console.log(res);  
      if(res && res.success==true){
        // this.cu=res.current;
        this.hargaData=res.data;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  async simpanHarga(){
    this.submit=true;
    if (this.validateForm.valid) {
      return this.toast.open({ type: "danger", text: "Mohon isi field yang di perlukan" });
    }
    let prm = {
     ...this.allForm()
    }
    if (this.isEdit){
      const res:any = await this.apiService.updateContactPrice(this.vendorId, prm,'vendor',this.editData.id);
      if (res.success){
        return this.toast.open({ type: "success", text: "Harga  berhasil di update" });
      }else{
        return this.toast.open({ type: "danger", text: res.err });
      }
    }else{
      console.log('save harga',prm)
      const res:any = await this.apiService.saveContactPrice(this.vendorId, prm,'vendor');
      console.log(res);
      if (res.success){
        this.getHargaByKey();
        return this.toast.open({ type: "success", text: "Harga berhasil dimpan" });
      }else{
        return this.toast.open({ type: "danger", text: res.err });
      }
    }
  }

}
