import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';

const globalDate = new Date();
@Component({
  selector: 'app-modal-shipment-export',
  templateUrl: './modal-shipment-export.component.html',
  styleUrls: ['./modal-shipment-export.component.scss']
})
export class ModalShipmentExportComponent implements OnInit {
  startDate: any;
  endDate: any;
  constructor(
    private toaster: Toaster,
    public activeModal:NgbActiveModal
  ) { }

  ngOnInit(): void {
  }


  getDate(event) {
    console.log(event);
    this.startDate = event.fromDate;
    this.endDate = event.endDate;
  }

  closeModal(){
    this.activeModal.close({
      status: 'close'
    });
  }

  exportData(){
    if(this.startDate && this.endDate){
      this.activeModal.close({
        status: 'export',
        startDate: this.startDate,
        endDate: this.endDate
      });
    }else{
      this.toaster.open({type:'danger', text: 'Tanggal belum dipilih'});
    }
  }

}
