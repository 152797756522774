import { Injectable } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
@Injectable({
  providedIn: 'root'
})
export class MessageService {
  constructor(
    private toast:Toaster
  ) { }
  public showError(params:any){
  console.log(Array.isArray(params))
  if (params &&  Array.isArray(params) ){
    for (let i = 0; i < params.length; i++) {
      this.toast.open({type:'danger',text:params[i].param})
    }
  }
  if (params && params){
    this.toast.open({type:'danger',text:params})
  }
  }
}
