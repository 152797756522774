import { Component, OnInit } from "@angular/core";
import { Toaster } from "ngx-toast-notifications";
import { ChartType } from "src/app/model";
import { ApiService } from "src/app/services/api.service";
import { DashboardService } from "src/app/services/dashboard.service";

import { EventService } from "src/app/services/event.service";
const globalDate = new Date();
@Component({
  selector: "app-chart-shipment-status",
  templateUrl: "./chart-shipment-status.component.html",
  styleUrls: ["./chart-shipment-status.component.scss"],
})
export class ChartShipmentStatusComponent implements OnInit {
  dataShipment: any;
   bulan:Array<string>=['JAN','FEB','MAR','APR','MEI','JUN','JUL','AGU','SEP','OKT','NOV','DES'];
   isLoadingData=false;
   typeActive:any;
   startDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1);
  endDate: any = new Date(
    globalDate.getFullYear(),
    globalDate.getMonth() + 1,
    0
  );
   
  shipmentChart: ChartType = {
    chart: { height: 350, type: "bar", toolbar: { show: false } },
    plotOptions: {
      bar: {
        horizontal: false,
        endingShape: "rounded",
        columnWidth: "45%",
      },
    },
    dataLabels: {
      enabled: false,
    },
    colors: ["#5c08bd","#f10b31", "#99970f"],
    series: [
      {
        type:"bar",
        name: "Total Shipment",
        data: [],
      },
      {
        type:"bar",
        name: "Intransit",
        data: [],
      },
      {
        type:"bar",
        name: "os Penerima",
        data: [],
      },
    ],
    xaxis: { categories: [] },
    yaxis: {
      title: {
        text: "(Ribuan)",
      },
    },
    fill: {
      opacity: 1,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
  };

  constructor(private dashboardService: DashboardService, private toaster: Toaster,
    private eventService: EventService,
    ) {
      this.eventService.periodeData.subscribe((data)=>{
        console.log(data);
        this.typeActive = data.typeActive;
        this.startDate = data.startDate;
        this.endDate = data.endDate;
        this.getData();
      });
    }

  ngOnInit(): void {
  
    // this.getData();
  }

  async getData() {
    console.log('----');
    this.isLoadingData=true;
    try{
      const res: any = await this.dashboardService.getDashboardShipmentByPeriods(
        this.startDate,
        this.endDate,
        this.typeActive
      );
      if (res.success) {
        console.log(res);
      this.dataShipment =res.data;
      this.createChart(this.dataShipment);
    } else {
      this.toaster.open({ type: "danger", text: "Error" });
    }
    
    } catch (err){
      this.isLoadingData=false;
      console.log(err)
    }
    
    
    
  }
  createChart(data:Array<{}>){
    
    this.shipmentChart = {
      chart: { height: 350, type: "bar", toolbar: { show: false } },
      dataLabels: {
        enabled: false,
      },
      series: [
        {
          type:"bar",
          name: "Total Shipment",
          data: [],
        },
        {
          type:"bar",
          name: "Intransit",
          data: [],
        },
        {
          type:"bar",
          name: "os Penerima",
          data: [],
        },
      ],
      xaxis: {
        categories: [],
      },
      colors: ["#5c08bd","#f10b31", "#99970f"],
      legend: {
        position: "bottom",
      },
      fill: {
        opacity: 1,
      },
    };
  
    for (const f of this.dataShipment) {
      if (this.typeActive==='harian'){
        this.shipmentChart.xaxis.categories.push(
            `${f.hari}`
        );
      }
      if (this.typeActive==='bulanan'){
        this.shipmentChart.xaxis.categories.push(
          `${f.Bulan}-${f.Tahun}`
        );
      }
      if (this.typeActive==='tahunan'){
        this.shipmentChart.xaxis.categories.push(
          `${f.Tahun}`
        );
      }

      this.shipmentChart.series[0].data.push(
        f.total_shipment
      );
      this.shipmentChart.series[1].data.push(
        (+f.os_arrival)+(+f.os_delivery)+(+f.os_departure)+(+f.os_incoming_wh)+(+f.os_manifest)
      );
      this.shipmentChart.series[2].data.push(
        f.os_received
      );
      // this.shipmentChart.series[1].data.push(f.Weight / 1000);
      // this.shipmentChart.series[2].data.push(f.Awb);
    }
    this.isLoadingData = false;


  }
}
