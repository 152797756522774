<!-- <div class="dropdown" ngbDropdown>
    <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown"
        aria-haspopup="true" aria-expanded="false"><i class="mdi mdi-wallet me-1"></i> <span
            class="d-none d-sm-inline-block" ngbDropdownToggle>Daftar Cabang {{cabangAktif?.alias}} <i
                class="mdi mdi-chevron-down"></i></span></button>
    <div class="dropdown-menu dropdown-menu-end dropdown-menu-md" ngbDropdownMenu>
        <div class="dropdown-item-text">
            <div>
                <p class="text-muted mb-2">{{cabangAktif?.company_id}}</p>
            </div>
        </div>

        <div class="dropdown-divider"></div>

        <a class="dropdown-item text-wrap" href="javascript: void(0);" (click)="pilihCabang(item.company_id)" *ngFor="let item of dataList">
            {{item.company_id}} - {{item.company_name}} 
        </a>

       

        <div class="dropdown-divider"></div>

         
    </div>
</div> -->
 
<div class="dropdown d-inline-block" ngbDropdown>
    <button type="button" class="btn btn-info dropdown-toggle" data-toggle="dropdown"
    aria-haspopup="true" aria-expanded="false"><i class="mdi mdi-wallet me-1"></i> <span
        class="d-none d-sm-inline-block" ngbDropdownToggle>Daftar Cabang  {{cabangAktif?.company_id}} - {{cabangAktif?.alias}} <i
            class="mdi mdi-chevron-down"></i></span></button>
    <div class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
      aria-labelledby="page-header-notifications-dropdown" ngbDropdownMenu>
      <div class="p-3">
        <div class="row align-items-center">
          <div class="col">
            <h6 class="m-0">Pilih Cabang</h6>
          </div>
          <div class="col-auto">
            {{cabangAktif?.company_id}} - {{cabangAktif?.alias}}
          </div>
        </div>
      </div>
      <ngx-simplebar style="position: relative; height: 230px;" data-simplebar-auto-hide="true">
        
        <a href="javascript: void(0);" class="text-reset notification-item" *ngFor="let item of dataList"  (click)="pilihCabang(item.company_id)" >
          <div class="media">
            <div class="avatar-xs me-3" *ngIf="item.company_id=='ALL'">
              <span class="avatar-title bg-danger rounded-circle font-size-16">
                <i class="bx bx-home-circle"></i>
              </span>
            </div>
            <div class="avatar-xs me-3" *ngIf="item.company_id!=='ALL'">
                <span class="avatar-title bg-info rounded-circle font-size-16">
                  <i class="bx bx-buildings"></i>
                </span>
              </div>
            <div class="media-body">
              <h6 class="mt-0 mb-1">{{item.company_id}} </h6>
              <div class="font-size-12 text-muted">
                <p class="mb-1"> {{item.company_name}} - {{item.alias}} </p>
 
              </div>
            </div>
          </div>
        </a>
        
      </ngx-simplebar>
      <div class="p-2 border-top d-grid">
        <a class="btn btn-sm btn-link font-size-14 text-center" href="javascript:void(0)">
          <i class="mdi mdi-arrow-right-circle me-1"></i> <span key="t-view-more"> Smua</span>
        </a>
      </div>
    </div>
  </div>