import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from 'src/app/services/global';
import { SaInvoiceService } from 'src/app/services/sa/sa-invoice.service';
@Component({
  selector: 'app-modal-sa-sewagudang',
  templateUrl: './modal-sa-sewagudang.component.html',
  styleUrls: ['./modal-sa-sewagudang.component.scss']
})
export class ModalSaSewagudangComponent implements OnInit {
  @Input() fromParent;
  showInfo:boolean=true;
  showDetail:boolean=false;
  dataDetail:any;
  judul:string="Daftar Sewa Gudang Inbound dan outbound";
  type:string;
  cid:string;
  hub:string;
  companyId:string;
  isTrx:boolean=false;
  dataList:any;
  isSelect:boolean=false;
  global={
    ...GlobalConstants.x
  };
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  
  constructor(
    public activeModal:NgbActiveModal,
    private SaService:SaInvoiceService,
    private toast:Toaster
  ) { 
    this.pgn={
      totalItems:0,
      itemsPerPage:0,
      limit:this.global.limitData,
      currentPage:1,
      prevPage:0,
      term:null
    };
  }

  ngOnInit(): void {
    this.companyId=this.fromParent.companyId;
    this.type=this.fromParent.type;
    this.cid=this.fromParent.cid;
    this.hub=this.fromParent.hub;
    this.isTrx=this.fromParent.isTrx?true:false;
    this.isSelect=this.fromParent.isSelect||false;
    this.loadPage(1);
    
  }
  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData(this.type,this.cid,this.hub);
    }
  }
  async getData(tipe:string,cid?:string,hub?:string){
    try {
      console.log(cid)
    let res:any;
      if (cid){
        console.log('--->>>>>')
        res  = await this.SaService.getSewaGudangUninvoice(this.companyId,this.pgn.currentPage,this.pgn.limit,this.pgn.term,tipe,cid);
      }
      console.log(res)
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
      }else{
        this.toast.open({type:'danger',text:res.err??'Error'})
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  pilihData(data:any){
    this.activeModal.close(data.smu_no);
  }
  showData(data:any){
    this.showInfo=false;
    this.showDetail=true;
    this.dataDetail=data;
  }
  hideData(){
    this.showInfo=true;
    this.showDetail=false;
    this.dataDetail={};
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  getDataShipment(type:string){
  }


}
