<form class="row row-cols-sm-auto">
    <div class="col-md-6">
        <div class="dp-hidden position-absolute">
            <div class="input-group">
                <input name="datepicker" class="form-control" ngbDatepicker #datepicker="ngbDatepicker" [autoClose]="'outside'" (dateSelect)="onDateSelection($event)" [displayMonths]="2" [dayTemplate]="t" outsideDays="hidden" [startDate]="fromDate!" tabindex="-1">
                <ng-template #t let-date let-focused="focused">
                    <span class="custom-day" [class.focused]="focused" [class.range]="isRange(date)" [class.faded]="isHovered(date) || isInside(date)" (mouseenter)="hoveredDate = date" (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
                </ng-template>
            </div>
        </div>
        <div class="input-group">
            <input #dpFromDate class="form-control" type="date" placeholder="yyyy-mm-dd" name="dpFromDate" [value]="formatter.format(fromDate)" (input)="fromDate = validateInput(fromDate, dpFromDate.value)">
            <!-- <button class="btn btn-primary" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar"></i></button> -->
        </div>
    </div>
    <div class="col-md-6">
        <div class="input-group">
            <input #dpToDate class="form-control" type="date" placeholder="yyyy-mm-dd" name="dpToDate" [value]="formatter.format(toDate)" (input)="toDate = validateInput(toDate, dpToDate.value)">
            <button class="btn btn-primary" (click)="datepicker.toggle()" type="button"><i class="fa fa-calendar"></i></button>
            <button class="btn btn-outline-success" (click)="setDate()" type="button"><i class="fa fa-search"></i></button>
        </div>
    </div>
</form>