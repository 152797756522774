<div class="row section">
    <div class="col-md-6">
        <img src="{{global.logoUrlBig}}" alt="" width="300">
    </div>
    <div class="col-md-6 text-end">
        <h1 class="bold">{{data?.company_name}}</h1>
        <p class="text">{{data?.address1}}</p>
        <p class="text" *ngIf="data?.phone1">{{data?.phone1}}</p>
        <p class="text">{{data?.email}}</p>
    </div>
</div>