<div class="modal-header">
    <h5 class="modal-title mt-0">Large modal</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div class="container">
        
        <p class="h6">Shipment</p>
        <hr/>
        <div class="row">
          <div class="col">
            <p class="h6">h6. Bootstrap heading</p>
            <small class="text-muted">With faded secondary text</small>
          </div>
          <div class="col-6">
            2 of 3 (wider)
          </div>
          <div class="col">
            3 of 3
          </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col">
              1 of 3
            </div>
            <div class="col-6">
              2 of 3 (wider)
            </div>
            <div class="col">
              3 of 3
            </div>
          </div>
      
      </div>

    
</div>
<div class="modal-footer">
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>