import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-shipment-info',
  templateUrl: './modal-shipment-info.component.html',
  styleUrls: ['./modal-shipment-info.component.scss']
})
export class ModalShipmentInfoComponent implements OnInit {


  @Input() fromParent;

  constructor(
    public activeModal:NgbActiveModal
  ) { }

  ngOnInit(): void {
    console.log(this.fromParent);
  }

  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
  
}
