import { environment } from "src/environments/environment";
export class GlobalConstants {
    public static logoUrl: string = `${environment.publicUrl}/files/logo/logo_profile.png`
    public static siteTitle: string = "title";
    public static x:any={
        logoUrl:`${environment.publicUrl}/files/logo/logo_profile_white.png`,
        logoUrlBig:`${environment.publicUrl}/files/logo/logo_white.png`,
        defaultFiles:`${environment.publicUrl}/files/`,
        title:  "PT.BINTAN BATAM SUKSES",
        limitData:environment.limitData,
        ppn:environment.ppn,
        admin:1000,
        formatPrint:{
            RESI_1:'RESI_1',
            RESI_2:'RESI_2'
        }
    };
    public static typeHarga:any=['flat','fixed'];
    public static cariArrayData(nameKey:any,myArray:any,field:string){
        for (var i = 0; i < myArray.length; i++) {
            if (field==='id'){
                if (myArray[i][field] === nameKey) {
                    return myArray[i];
                }
            }else{
                if (myArray[i][field].toString() === nameKey.toString()) {
                    return myArray[i];
                }
            }
            
        }
    }

    public static displayGreeting(){
        let day = new Date();
        let hr = day.getHours();
        let info ='';
        if (hr >= 0 && hr < 12) {
            info = 'Selamat Pagi !'
        } else if (hr == 12) {
            info = 'Selamat Siang !'
        } else if (hr >= 12 && hr <= 17) {
            info = 'Selamat Siang !'
        } else {
            info = 'Selamat Malam !'
        }
        return info;
    }


}


export function popupwindow(url, title, w, h) {
    var left = (screen.width/2)-(w/2);
    var top = (screen.height/2)-(h/2);
    return window.open(url, title, 'toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=no, resizable=no, copyhistory=no, width='+w+', height='+h+', top='+top+', left='+left);
  }
  