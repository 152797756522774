
<div class="card">
    
    <div class="spinner-grow text-primary m-1" role="status" *ngIf="isLoadingData">
        <span class="sr-only">Loading...</span>
    </div>
    <div class="card-body">
        <h4 class="card-title">Pengiriman</h4>
        <hr />
        
        <apx-chart class="apex-charts" dir="ltr" [series]="shipmentChart.series || []" [chart]="shipmentChart?.chart" [plotOptions]="shipmentChart?.plotOptions" [yaxis]="shipmentChart?.yaxis" [grid]="shipmentChart?.grid" [tooltip]="shipmentChart?.tooltip"
        [stroke]="shipmentChart?.stroke" [dataLabels]="shipmentChart?.dataLabels" [xaxis]="shipmentChart?.xaxis" [colors]="shipmentChart?.colors" [fill]="shipmentChart?.fill">
    </apx-chart>
        
       
        
    </div>
</div>
