import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { SuperadminGuard } from './guards/superadmin.guard';
import { LayoutComponent } from './layouts/layout.component';
import { Page404Component } from './views/extrapages/page404/page404.component';
import { CSGuard } from './guards/cs.guard';
const admin: Routes = [
  { path: 'account', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./views/globals/globals.module').then(m => m.GlobalsModule), canActivate: [AuthGuard] },
  { path: 'contact', component: LayoutComponent, loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule), canActivate: [AuthGuard] },
  { path: 'shipment', component: LayoutComponent, loadChildren: () => import('./views/shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [AuthGuard] },
  { path: 'manifest', component: LayoutComponent, loadChildren: () => import('./views/manifest/manifest.module').then(m => m.ManifestModule), canActivate: [AuthGuard] },
  { path: 'assignment', component: LayoutComponent, loadChildren: () => import('./views/assignment/assignment.module').then(m => m.AssignmentModule), canActivate: [AuthGuard] },
  { path: 'arrival', component: LayoutComponent, loadChildren: () => import('./views/arrival/arrival.module').then(m => m.ArrivalModule), canActivate: [AuthGuard] },
  { path: 'mastersetting', component: LayoutComponent, loadChildren: () => import('./views/master/master.module').then(m => m.MasterModule), canActivate: [AuthGuard] },
  { path: 'delivery-sheet', component: LayoutComponent, loadChildren: () => import('./views/delivery-sheet/delivery-sheet.module').then(m => m.DeliverySheetModule), canActivate: [AuthGuard] },
  { path: 'status', component: LayoutComponent, loadChildren: () => import('./views/status/status.module').then(m => m.StatusModule), canActivate: [AuthGuard] },
  { path: 'smu', component: LayoutComponent, loadChildren: () => import('./views/smu/smu.module').then(m => m.SmuModule), canActivate: [AuthGuard] },
  { path: 'print', loadChildren: () => import('./views/print/print.module').then(m => m.PrintModule), canActivate: [AuthGuard] },
  { path: 'sa-invoice', component: LayoutComponent, loadChildren: () => import('./views_sa/invoice-sa/invoice-sa.module').then(m => m.InvoiceSaModule), canActivate: [AuthGuard] },
  { path: 'sa-finance', component: LayoutComponent, loadChildren: () => import('./views_sa/finance-sa/finance-sa.module').then(m => m.FinanceSaModule), canActivate: [AuthGuard] },
  // { path: '', component: LayoutComponent, loadChildren: () => import('./pages/pages.module').then(m => m.PagesModule), canActivate: [AuthGuard] },
  // { path: 'pages', loadChildren: () => import('./extrapages/extrapages.module').then(m => m.ExtrapagesModule), canActivate: [AuthGuard] },
  { path: 'sa-shipment', component: LayoutComponent, loadChildren: () => import('./views_sa/shipment-sa/shipment-sa.module').then(m => m.ShipmentSaModule), canActivate: [AuthGuard] },  
];

const cs: Routes = [
  { path: 'account', loadChildren: () => import('./views/account/account.module').then(m => m.AccountModule) },
  { path: '', component: LayoutComponent, loadChildren: () => import('./views/globals/globals.module').then(m => m.GlobalsModule), canActivate: [CSGuard] },
  { path: 'contact', component: LayoutComponent, loadChildren: () => import('./views/contacts/contacts.module').then(m => m.ContactsModule), canActivate: [CSGuard] },
  { path: 'shipment', component: LayoutComponent, loadChildren: () => import('./views/shipment/shipment.module').then(m => m.ShipmentModule), canActivate: [CSGuard] },
  { path: 'manifest', component: LayoutComponent, loadChildren: () => import('./views/manifest/manifest.module').then(m => m.ManifestModule), canActivate: [CSGuard] },
  { path: 'assignment', component: LayoutComponent, loadChildren: () => import('./views/assignment/assignment.module').then(m => m.AssignmentModule), canActivate: [CSGuard] },
  { path: 'arrival', component: LayoutComponent, loadChildren: () => import('./views/arrival/arrival.module').then(m => m.ArrivalModule), canActivate: [CSGuard] },
  { path: 'mastersetting', component: LayoutComponent, loadChildren: () => import('./views/master/master.module').then(m => m.MasterModule), canActivate: [CSGuard] },
  { path: 'delivery-sheet', component: LayoutComponent, loadChildren: () => import('./views/delivery-sheet/delivery-sheet.module').then(m => m.DeliverySheetModule), canActivate: [CSGuard] },
  { path: 'status', component: LayoutComponent, loadChildren: () => import('./views/status/status.module').then(m => m.StatusModule), canActivate: [CSGuard] },
  { path: 'smu', component: LayoutComponent, loadChildren: () => import('./views/smu/smu.module').then(m => m.SmuModule), canActivate: [CSGuard] },
  { path: 'print', loadChildren: () => import('./views/print/print.module').then(m => m.PrintModule), canActivate: [CSGuard] },
];

const superAdmin:Routes=[
  { path: 'company', component: LayoutComponent, loadChildren: () => import('./views/company/company.module').then(m => m.CompanyModule), canActivate: [SuperadminGuard] },
  { path: 'sa-shipment', component: LayoutComponent, loadChildren: () => import('./views_sa/shipment-sa/shipment-sa.module').then(m => m.ShipmentSaModule), canActivate: [SuperadminGuard] },
  { path: 'sa-invoice', component: LayoutComponent, loadChildren: () => import('./views_sa/invoice-sa/invoice-sa.module').then(m => m.InvoiceSaModule), canActivate: [SuperadminGuard] },
  { path: 'sa-finance', component: LayoutComponent, loadChildren: () => import('./views_sa/finance-sa/finance-sa.module').then(m => m.FinanceSaModule), canActivate: [SuperadminGuard] },
  { path: 'sa-admin', component: LayoutComponent, loadChildren: () => import('./views_sa/admin_sa/admin-sa.module').then(m => m.AdminSaModule), canActivate: [SuperadminGuard] },
 

]


const routes:any=[
  ...admin,
  ...superAdmin,
  ...cs,
   { path: '**', component: Page404Component },

]


@NgModule({

  imports: [RouterModule.forRoot(routes, { scrollPositionRestoration: 'top', relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
