<footer class="footer">
    <div class="container-fluid">
        <div class="row">
            <div class="col-sm-6">
                {{year}} © Koding Integrasi Solusi.
            </div>
            <div class="col-sm-6">
                <div class="text-sm-end d-none d-sm-block">
                    Design & Develop by BIKINKODING <small>0.5</small>
                </div>
            </div>
        </div>
    </div>
</footer>