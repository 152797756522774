<div class="modal-header">
    <h5 class="modal-title mt-0">Tracing Pengiriman</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div   class="container">
        <p class="h2" *ngIf="dataDetail?.is_cash"><i class="fa fa-money text-primary"></i> Detail Shipment</p>
        <hr/>
        <div class="row">
          <div class="col">
            <p class="h6">No Resi</p>
            <p class="text-muted">{{dataDetail?.shipment_awbm}}</p>
          </div>
          <div class="col">
            <p class="h6">Tanggal Pengiriman</p>
            <p class="text-muted">{{dataDetail?.shipment_date  | date : "dd-MM-yyyy" }}</p>
          </div>
          <div class="col">
            <p class="h6">Referensi</p>
            <p class="text-muted">{{dataDetail?.shipment_do}}</p>
          </div>
          <div class="col">
            <p class="h6">Moda</p>
            <p class="text-muted"> <i class ="fi text-primary" [ngClass]="{'flaticon-airplane-frontal-view': dataDetail?.moda_id === 'A', 'flaticon-delivery-truck' :  dataDetail?.moda_id === 'L' ,'flaticon-sea-ship': dataDetail?.moda_id === 'S'}"> </i> {{dataDetail?.moda_id=='A'?'AIR':dataDetail?.moda_id=='L'?'LAND':'SEA' }}</p>
          </div>
          <div class="col">
            <p class="h6">Service</p>
            <p class="text-muted">{{dataDetail?.service_id}}</p>
          </div>
          <div class="col">
            <p class="h6">UOM</p>
            <p class="text-muted">{{dataDetail?.weight}} - {{dataDetail?.uom_id}}</p>
          </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col">
              <p class="h6">Pengirim</p>
                <div class="row text-muted">
                  <div class="col-3">Nama</div>
                  <div class="col-9">{{dataDetail?.partner_id}}-{{dataDetail?.partner_name}}</div>
                  <div class="col-3">Alamat</div>
                  <div class="col-9">{{dataDetail?.partner_address1}}</div>
                  <div class="col-3">Phone</div>
                  <div class="col-9">{{dataDetail?.partner_phone1}}</div>
                  <div class="col-3">Asal</div>
                  <div class="col-9">{{dataDetail?.origin}} - {{dataDetail?.originname}}</div>
                </div>
            </div>
            <div class="col">
              <p class="h6">Penerima</p>
                <div class="row text-muted">
                  <div class="col-3">Nama</div>
                  <div class="col-9">{{dataDetail?.receiver_name}}</div>
                  <div class="col-3">Alamat</div>
                  <div class="col-9">{{dataDetail?.receiver_address1}}</div>
                  <div class="col-3">Phone</div>
                  <div class="col-9">{{dataDetail?.receiver_phone1}}</div>
                  <div class="col-3">Tujuan</div>
                  <div class="col-9">{{dataDetail?.destination}} - {{dataDetail?.destinationname}}</div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col">
              <div class="row text-muted">
                <div class="col-3">Pickup By</div>
                <div class="col-9">{{dataDetail?.pickup_by}}</div>
                <div class="col-3">Sales By</div>
                <div class="col-9">{{dataDetail?.sales_by}}</div>
                
              </div>
          </div>
          <div class="col">
            <div class="row text-muted">
              <div class="col-3">Intruksi</div>
              <div class="col-9">{{dataDetail?.specialinst}}</div>
              <div class="col-3">Item Description</div>
              <div class="col-9">{{dataDetail?.itemdesc}}</div>
              <div class="col-3">Status</div>
              <div class="col-9"><span class="badge badge-pill badge-soft-info font-size-11 text-wrap" [ngClass]=" { 'badge-soft-danger': dataDetail?.last_status === 'PICKUP',
                'badge-soft-warning':dataDetail?.last_status === 'MANIFEST' }">{{dataDetail?.last_status_remark}}</span></div>
            </div>

          </div>
          <div class="col">
            <p class="h6">Status</p>
            <div class="">
              <ul class="verti-timeline list-unstyled">
                <li class="event-list" *ngFor="let x of dataDetail.history;">
                  <div class="event-timeline-dot">
                    <i class="bx bx-right-arrow-circle bx-fade-right"></i>
                  </div>
                  <div class="media">
                    <div class="me-3">
                      <i class="{{x.status.icon}} h2 text-primary"></i>
                    </div>
                    <div class="media-body">
                      <div>
                        <h6 class="mb-0">{{x.history_date | date : "dd-MM-yyyy HH:mm"}}</h6>
                        <p class="text-wrap mb-0">{{x.status_name}}</p>
                        <p class="text-muted text-wrap mb-0">{{x.description}}</p>
                        <div class="zoom-gallery d-flex">
                          <div>
                            <img [src]="imgUrl+x.pic1" alt="pic1"  width="50" *ngIf="x.pic1" (click)="openImage(x)">
                            <img [src]="imgUrl+x.pic2" alt="pic2"    width="50" *ngIf="x.pic2"  (click)="openImage(x)">
                            <img [src]="imgUrl+x.pic3" alt="pic3"  width="50" *ngIf="x.pic3"  (click)="openImage(x)">

                              
                          </div>
                      </div>
                      </div>
                    </div>
                  </div>
                </li>
                 
              </ul>
            </div>
            
            
          </div>
        </div>
        
    </div>
</div>
<div class="modal-footer">
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>