import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { SaCompanyService } from 'src/app/services/sa/sa-company.service';

@Component({
  selector: 'app-modal-sa-bank',
  templateUrl: './modal-sa-bank.component.html',
  styleUrls: ['./modal-sa-bank.component.scss']
})
export class ModalSaBankComponent implements OnInit {


  @Input() companyId:string;
  data:any={
    note:''
  };
  loadingSave:boolean=false;

  constructor(
    public activeModal:NgbActiveModal,
    private toast:Toaster,
    private SaCompany : SaCompanyService
  ) { }

  ngOnInit(): void {
  }
  closeModal(sendData) {

    this.activeModal.close({
      data:sendData
    });
  }

  async save(){
    console.log(this.data);
    this.loadingSave=true;
    const res: any = await this.SaCompany.saveBankList(this.data,this.companyId);
    console.log(res);
      if (res.success) {
        this.toast.open({
          type: "success",
          text: "Berhasil di update",
        });
        this.loadingSave = false;
      } else {
        this.loadingSave = false;
        return this.toast.open({ type: "danger", text: Array.isArray(res.err) ? res.err[0].msg : res.err });
      }


    
  }

}
