import { Component, Input, OnInit } from '@angular/core';
import { GlobalConstants } from 'src/app/services/global';
import { ApiService } from 'src/app/services/api.service';
import { Toaster } from 'ngx-toast-notifications';

@Component({
  selector: 'app-billing-list',
  templateUrl: './billing-list.component.html',
  styleUrls: ['./billing-list.component.scss']
})
export class BillingListComponent implements OnInit {
  @Input() dataList: any;
  @Input() type: string;
  @Input() tabActive: number;
  global={
    ...GlobalConstants.x
  };
  showBilling:boolean=false;
  isNew:boolean=false;
  
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };

  constructor(
    private apiService:ApiService,
    private toast:Toaster,
  ) { 
    console.log('billing list', this.type);
  }

  ngOnInit(): void {
  }

  klikYes(x){
    this.showBilling=false;
    if (!x.s){
      this.toast.open({type:'danger',text:'di batalkan'})
    }else{

      this.getData();
    }
    
  }
  addBilling(){
    console.log('add billing');
    this.showBilling=true;
  }

  async getData(){

  }
}
