import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ModalHubComponent } from 'src/app/modals/modal-hub/modal-hub.component';
import { ModalQuestionsComponent } from 'src/app/modals/modal-questions/modal-questions.component';
import { ModalShipmentComponent } from 'src/app/modals/modal-shipment/modal-shipment.component';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from "src/app/services/global";
import { MessageService } from "src/app/services/message.service";
@Component({
  selector: 'app-master-billing',
  templateUrl: './master-billing.component.html',
  styleUrls: ['./master-billing.component.scss']
})
export class MasterBillingComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() billingData: any;
  @Input() type: string;
  @Output() klikYes: EventEmitter<any> = new EventEmitter();

  validateForm!: FormGroup;
  constructor(
    private toaster: Toaster,
    private apiService: ApiService,
    private fb: FormBuilder,
    private modal: NgbModal,
    private message:MessageService
  ) { 
    this.validateForm = this.fb.group({
      b_id: [{ value: null, disabled: true }],
      b_name: [{value:null}, [Validators.required]],
      b_description: [{value:null},],
      b_isActive: [{value:true},],
      b_isTax: [{value:true},],

    });

  }

  ngOnInit(): void {
  }

}
