<div class="row">
    <div class="col-md-4 col-9">
      <h5 class="font-size-15 mb-1">{{title}}</h5>
  </div>
  <div class="col-md-8 col-3">
    <ul class="list-inline user-chat-nav text-end mb-0">
        <li class="list-inline-item">
            <button class="btn btn-sm btn-info" (click)="showFilter()"><i class="fa fa-history"></i></button>
        </li>
    </ul>
  </div>
  <div class="col-md-12" *ngIf="isShowFilter">
    <div class="ms-auto" >
      <ul class="nav nav-pills">
          <li class="nav-item"><a class="nav-link" href="javascript: void(0);" [ngClass]="{'active': isActive === 'harian'}" (click)="onChangePeriods('harian')" >Harian</a>
          </li>
          <li class="nav-item"><a class="nav-link" href="javascript: void(0);" [ngClass]="{'active': isActive === 'bulanan'}"  (click)="onChangePeriods('bulanan')">Bulan</a>
          </li>
          <li class="nav-item">
              <a class="nav-link" href="javascript: void(0);"
                  [ngClass]="{'active': isActive === 'tahunan'}"  (click)="onChangePeriods('tahunan')">Tahun</a>
          </li>
          <li class="nav-item" *ngIf="isShowDate">
            <div class="d-flex">
              <div class="input-group">
                  <span class="input-group-text" id="option-startDate">Dari</span>
                  <input class="form-control" type="date" [ngModel]="fromDate  | date:'yyyy-MM-dd'" id="tgl_awal" name="tgl_awal" (ngModelChange)="onChangeDate($event,'tgl_start')" required>
              </div>
              <div class="input-group" style="margin-left: 10px;">
                  <span class="input-group-text" id="option-endDate">s/d</span>
                  <input class="form-control" type="date" [ngModel]="toDate  | date:'yyyy-MM-dd'" id="tgl_akhir" name="tgl_akhir" (ngModelChange)="onChangeDate($event,'tgl_end')" required>
              </div>
               
          </div>
        </li>
  
          <li class="nav-item" *ngIf="isShowMonth">
            <div class="d-flex">
              <div class="input-group">
                <span class="input-group-text" id="option-startMonth">Dari</span>
                <input class="form-control" type="month"   [ngModel]="fromMonth  | date:'yyyy-MM'"  (ngModelChange)="onChangeMonth($event,'tgl_start')" id="startMonth">
              </div>
              <div class="input-group" style="margin-left: 10px;">
                <span class="input-group-text" id="option-endMonth">s/d</span>
                <input class="form-control" type="month"   [ngModel]="toMonth  | date:'yyyy-MM'"  (ngModelChange)="onChangeMonth($event,'tgl_end')" id="toMonth">
              </div>
  
            </div>
            <div class="mb-3 row">
              <div class="col-md-6">
              
              </div>
              <div class="col-md-6">
  
            </div>
          </div>
          </li>
          <li class="nav-item" *ngIf="isShowYear">
            <div class="d-flex">
              <div class="input-group">
                <span class="input-group-text" id="option-startYear">Dari</span>
                <input class="form-control" type="number"   [ngModel]="fromYear  | date:'yyyy'"  (ngModelChange)="onChangeYear($event,'tgl_start')" id="startYear">
              </div>
              <div class="input-group" style="margin-left: 10px;">
                <span class="input-group-text" id="option-endYear">s/d</span>
                <input class="form-control" type="number"   [ngModel]="toYear  | date:'yyyy'"  (ngModelChange)="onChangeYear($event,'tgl_end')" id="toYear">
              </div>
  
            </div>
            <div class="mb-3 row">
              <div class="col-md-6">
              
              </div>
              <div class="col-md-6">
  
            </div>
          </div>
          </li>
  
          <li class="nav-item">
            <div class="input-group mb-3">
              <button class="btn btn-danger" (click)="doFilter()"><i class="fa fa-filter"></i> View</button>
            </div>
          
          </li>
      </ul>
  </div>
  </div>
  </div>
  