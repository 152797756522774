<div class="container">
    <div class="row">
        <div class="col-8">
            <h4 class="card-title mt-0"> Daftar Provinsi <span class="badge bg-primary rounded-pill">{{ total }}</span></h4>
        </div>
        <div class="col-4">
            <div class="input-group mb-3">
                <input type="text" class="form-control" [(ngModel)]="term" placeholder="Cari Provinsi..." aria-label="Cari..." aria-describedby="btn-cari" />
                <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="search()">
                        <i class="fa fa-search"></i>
                    </button>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <div class="table-responsive">
                <table class="table align-middle table-hover">
                    <thead class="table-light">
                        <tr>
                            <th scope="col">ID</th>
                            <th scope="col">Nama</th>
                            <th scope="col">Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr *ngFor="let item of datalist;let i = index">
                            <td>{{ item.province_id }}</td>
                            <td>{{ item.province_name }}</td>
                            <td>
                                <button type="button" class="btn btn-primary waves-effect waves-light" (click)="pilih(item)">
                                    <i class="bx bxs-badge-check font-size-16 align-middle me-2"></i>Pilih
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <ngb-pagination class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="page" [collectionSize]="total" [maxSize]="10" [pageSize]="5" [rotate]="true"></ngb-pagination>
            </div>
        </div>
    </div>
</div>