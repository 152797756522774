import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-modal-questions',
  templateUrl: './modal-questions.component.html',
  styleUrls: ['./modal-questions.component.scss']
})
export class ModalQuestionsComponent implements OnInit {
  @Input() title:string;
  @Input() content:string;
  constructor(
    public activeModal:NgbActiveModal
  ) { }
  ngOnInit() {
  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }
}
