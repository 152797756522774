import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { User } from '../models/auth.models';
import { environment } from 'src/environments/environment';
const httpOptions = {
    headers: new HttpHeaders({ Accept: "application/json" }),
  };
@Injectable({ providedIn: 'root' })
export class UserService {
    constructor(private httpClient: HttpClient) {}

    // getAll() {
    //     return this.http.get<User[]>(`/api/login`);
    // }

    // register(user: User) {
    //     return this.http.post(`/users/register`, user);
    // }
    driver(params:any){
        return this.httpClient
        .post<any>(`${environment.apiUrl}/auth/setdriver`, params, httpOptions)
        .toPromise();
    }

    register(data:any) {
        return this.httpClient.post<any>(`${environment.apiUrl}/sa/setadmin`, data).toPromise();
    }

    
}
