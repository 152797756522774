<div class="row">
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-red">
                <div class="card-block">
                    <h6 class="m-b-20">OS Incoming WH</h6>
                    <h2 class="text-end"><a href="javascript:void(0)"  ngbTooltip="Detail klik disini" (click)="openModal('OSINCOMINGWH')"><i class="fi flaticon-delivery-package-with-umbrella-symbol f-left"></i><span> {{summaryShipment.osIncomingWH||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)" ngbTooltip="Detail klik disini"  (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-yellow">
                <div class="card-block">
                    <h6 class="m-b-20">OS Manifest</h6>
                    <h2 class="text-end"><a href="javascript:void(0)"  ngbTooltip="Detail klik disini" (click)="openModal('OSMANIFEST')"><i class="fi flaticon-delivery-transportation-machine f-left"></i><span>{{summaryShipment.osManifest||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)" (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-blue">
                <div class="card-block">
                    <h6 class="m-b-20">OS Keberangkatan</h6>
                    <h2 class="text-end"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('OSDEPARTURE')"><i class="fi flaticon-airplane-around-earth f-left"></i><span>{{summaryShipment.osDeparture||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-cloud">
                <div class="card-block">
                    <h6 class="m-b-20">OS Kedatangan</h6>
                    <h2 class="text-end"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('OSARRIVAL')"><i class="fi flaticon-airplane-in-vertical-ascending-position f-left"></i><span>{{summaryShipment.osArrival||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-purple">
                <div class="card-block">
                    <h6 class="m-b-20">OS Delivery</h6>
                    <h2 class="text-end"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('OSDELIVERY')"><i class="fi flaticon-24-hours-delivery f-left"></i><span>{{summaryShipment.osDelivery||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)"  ngbTooltip="Detail klik disini" (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
    <div class="col-md-4">
        <div class="shipment-card">
            <div class="card bg-c-green">
                <div class="card-block">
                    <h6 class="m-b-20">OS Penerima</h6>
                    <h2 class="text-end"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('OSRECEIVE')"><i class="fi flaticon-delivery-box-on-a-hand f-left"></i><span>{{summaryShipment.osReceive||0|number}}</span></a></h2>
                    <p class="m-b-0"><a href="javascript:void(0)" ngbTooltip="Detail klik disini" (click)="openModal('TOTALSHIPMENT')">Shipment<span class="f-right">{{summaryShipment.totalShipment|number}}</span></a></p>
                </div>
            </div>
        </div>
    </div>
</div>