<div class="card">
    <div class="card-body">
       
        <div class="row">
            <div class="col-md-6">
                <h4 class="card-title">Informasi Pengiriman</h4>
                <div class="form-group" [hidden]="true">
                    <label for="cn" class="col-sm-3 form-control-label">Otomatis</label>
                    <div class="col-sm-6">
                        <input [ngModel]="DO.shipment_awb" type="text" class="form-control" id="cn" name="cn" placeholder="Auto Generate" disabled>
                    </div>
                </div>
                <div class="mb-0 row">
                    <label for="address1" class="col-md-3 col-form-label"></label>
                    <div class="col-sm-9">
                        <div class="form-group form-check">
                            <input type="checkbox" id="is_incoming_wh" name="is_incoming_wh" [ngModel]="isIncomingWh" class="form-check-input" [checked]="isIncomingWh" />
                            <label for="is_incoming_wh" class="form-check-label">Check jika shipment sudah di pickup</label>
                        </div>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="shipment_awbm" class="col-md-3 col-form-label">No Resi <span class="text-primary">*</span></label>
                    <div class="col-md-9">
                        <div class="input-group mb-1">
                            <input type="text" id="shipment_awbm" name="shipment_awbm" class="form-control" aria-label="shipment_awbm" [disabled]="isAutoNumber" [(ngModel)]="DO.shipment_awbm">
                            <div class="input-group-text">
                                <input class="form-check-input mt-0" id="isAutoNumber" name="isAutoNumber" type="checkbox" value="" aria-label="isAutoNumber" [(ngModel)]="isAutoNumber" [checked]="isAutoNumber">
                                <label for="isAutoNumber" class="form-check-label"> Auto Number</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="shipment_date" class="col-md-3 col-form-label">Tanggal<span class="text-primary">*</span></label>
                    <div class="col-md-4">
                        <input class="form-control" type="date" [ngModel]="DO.shipment_date  | date:'yyyy-MM-dd'" (ngModelChange)="onChangeDate($event,'shipment_date')" id="shipment_date" name="shipment_date" required>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="shipment_do" class="col-md-3 col-form-label">No Reff</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" [(ngModel)]="DO.shipment_do" id="shipment_do" name="shipment_do">
                    </div>
                </div>
                <div class="mt-2 row">
                    <label class="col-md-3 col-form-label">Metode Pembayaran</label>
                    <div class="col-md-4">
                        <select class="form-select" [(ngModel)]="DO.shipment_type_id" id="payment_method" name="payment_method">
                        <option value="CASH">Cash</option>
                        <option value="CREDIT">Invoice</option>
                        <option value="COLLECT">Collect</option>
                    </select>
                    </div>
                </div>
                <hr />
                <h4 class="card-title">Pengirim</h4>
                <div class="mb-1 row">
                    <label for="partner_id" class="col-md-3 col-form-label">ID Pengirim<span class="text-primary">*</span></label>
                    <div class="col-sm-9">
                        <div class="input-group">
                            <input type="text" class="form-control" placeholder="Kode Pengirim" id="partner_id" [(ngModel)]="DO.partner_id" aria-describedby="partner_id" />
                            <button class="btn btn-primary" type="button" id="btn_partner_id" (click)="openContact('CUSTOMER')"><i class="fas fa-users-cog"></i></button>
                        </div>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="partner_name" class="col-md-3 col-form-label">Nama Pengirim<span class="text-primary">*</span></label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" id="partner_name" [(ngModel)]="DO.partner_name" placeholder="Perusahaan / Perorangan">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="partner_pic" class="col-md-3 col-form-label">Shipper / PIC</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" id="partner_pic" [(ngModel)]="DO.partner_pic" placeholder="Person In Charge">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="partner_phone1" class="col-md-3 col-form-label">Telepon</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" placeholder="Telepon / mobile " [(ngModel)]="DO.partner_phone1" id="partner_phone1">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="partner_address1" class="col-md-3 col-form-label">Alamat <span class="text-primary">*</span> </label>
                    <div class="col-sm-9">
                        <textarea class="form-control" id="partner_address1" name="partner_address1" [ngModel]="DO.partner_address1" rows="4" placeholder="Alamat lengkap"></textarea>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="origin" class="col-md-3 col-form-label">Kota Asal <span class="text-primary">*</span></label>
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="text" class="form-control" id="origin" [ngModel]="DO.origin" aria-describedby="origin" disabled="true" />
                            <button class="btn btn-primary" type="button" id="origin" (click)="openModalDest('ORIGIN')"><i class="bx bx-right-top-arrow-circle "></i></button>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control" id="originname" name="originname" [ngModel]="DO.originname" placeholder="Nama kota asal" disabled="true" />
                    </div>
                </div>
                <hr/>
                <h4 class="card-title">Penerima</h4>
                <div class="mb-1 row">
                    <label for="receiver_id" class="col-md-3 col-form-label">Id Penerima</label>
                    <div class="col-md-9">
                        <div class="input-group">
                            <input class="form-control" type="text" value="" id="receiver_id" name="receiver_id" [(ngModel)]="DO.receiver_id" [disabled]="!DO.partner_id">
                            <button class="btn btn-primary" type="button" id="btn_partner_id" (click)="openModalSubContact()" [disabled]="!DO.partner_id"><i class="fas fa-user-friends"></i></button>
                        </div>


                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="receiver_name" class="col-md-3 col-form-label">Nama Penerima<span class="text-primary">*</span></label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" placeholder="Penerima (perusahaan/pribadi)" id="receiver_name" name="receiver_name" [(ngModel)]="DO.receiver_name">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="receiver_pic" class="col-md-3 col-form-label">Pic</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" id="receiver_pic" name="receiver_pic" [(ngModel)]="DO.receiver_pic">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="receiver_phone1" class="col-md-3 col-form-label">Telepon</label>
                    <div class="col-md-9">
                        <input class="form-control" type="text" value="" id="receiver_phone1" [(ngModel)]="DO.receiver_phone1">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="receiver_address1" class="col-md-3 col-form-label">Alamat <span class="text-primary">*</span></label>
                    <div class="col-sm-9">
                        <textarea class="form-control" id="receiver_address1" name="receiver_address1" rows="4" placeholder="Alamat  lengkap penerima" [(ngModel)]="DO.receiver_address1"></textarea>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="destination" class="col-md-3 col-form-label">Kota Tujuan<span class="text-primary">*</span></label>
                    <div class="col-md-4">
                        <div class="input-group">
                            <input type="text" class="form-control" id="destination" aria-describedby="destination" [(ngModel)]="DO.destination" disabled="true" /><button class="btn btn-primary" type="button" id="destination" (click)="openModalDest('DESTINATION')"><i class="bx bx-right-down-arrow-circle"></i></button>
                        </div>
                    </div>
                    <div class="col-md-5">
                        <input type="text" class="form-control" id="destinationname" name="destinationname" placeholder="Nama kota tujuan" [(ngModel)]="DO.destinationname" disabled="true" />
                    </div>
                </div>
                <hr/>
                <h4 class="card-title">Informasi Lain lain</h4>
                <hr />
                <div class="mb-1 row">
                    <label for="specialinst" class="col-md-3 col-form-label">Instruksi Pengiriman</label>
                    <div class="col-md-9">
                        <textarea class="form-control" id="specialinst" name="specialinst" rows="4" placeholder="Keterangan handling terhadap pengiriman" [(ngModel)]="DO.specialinst"></textarea>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="itemdesc" class="col-md-3 col-form-label">Isi barang </label>
                    <div class="col-md-9">
                        <textarea class="form-control" id="itemdesc" name="itemdesc" rows="4" placeholder="Keterangan isi barang " [(ngModel)]="DO.itemdesc"></textarea>
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <h4 class="card-title">Detail Pengiriman</h4>
                <div class="mb-1 row">
                    <label for="qty" class="col-md-3 col-form-label">Koli<span class="text-primary">*</span></label>
                    <div class="col-md-3">
                        <input class="form-control" type="number" id="qty" name="qty" [(ngModel)]="DO.qty">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="weight_actual" class="col-md-3 col-form-label">Berat<span class="text-primary">*</span></label>
                    <div class="col-md-3">
                        <input class="form-control" type="number" id="weight_actual" name="weight_actual" [(ngModel)]="DO.weight_actual" (ngModelChange)="onChangeVol($event)">
                    </div>
                    <div class="col-md-6">
                        <select class="form-select" [ngModel]="DO.uom_id" (ngModelChange)="onChangePickupUom($event)" id="uom_id" name="uom_id">
                            <option [value]="i?.uom_id" *ngFor="let i of uomData">{{i.uom_desc}}</option>
                        </select>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="weight_vol" class="col-md-3 col-form-label">Vol<span class="text-primary">*</span></label>
                    <div class="col-md-3">
                        <input class="form-control" type="number" value="1" id="weight_vol" name="weight_vol" [(ngModel)]="DO.weight_vol" (ngModelChange)="onChangeVol($event)">
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="weight" class="col-md-3 col-form-label">Berat Charge</label>
                    <div class="col-md-3">
                        <input class="form-control" type="number" value="1" id="weight" name="weight" [(ngModel)]="DO.weight_invoice">
                    </div>
                </div>
                <hr/>
                <h4 class="card-title">Harga Pengiriman</h4>

                <div class="mb-1 row">
                    <label for="charge" class="col-md-3 col-form-label">Biaya Kirim</label>
                    <div class="col-md-9">
                        <div class="input-group">
                            <input type="text" class="form-control text-end" id="charge" name="charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.charge" (ngModelChange)="onChangePrice($event)" aria-describedby="charge" />
                            
                            <button class="btn btn-primary" type="button" id="btn_partner_id" (click)="kalkulasiHarga()"><i class="mdi mdi-book-alert"></i> Kalkulasi</button>
                            <button class="btn btn-warning" type="button" id="btn_harga" (click)="openModalHarga()"><i class="bx bx-money"></i> Update Harga</button>
                        </div>

                        
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="pickup_charge" class="col-md-3 col-form-label">Biaya Pickup</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="pickup_charge" name="pickup_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.pickup_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="pickup_charge"
                        />
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="pickup_charge" class="col-md-3 col-form-label">Biaya Alat</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="tools_charge" name="tools_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.tools_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="tools_charge"
                        />
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="buruh_charge" class="col-md-3 col-form-label">Biaya buruh</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="buruh_charge" name="buruh_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.buruh_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="buruh_charge"
                        />
                    </div>
                </div>



                <div class="mb-1 row">
                    <label for="telly_charge" class="col-md-3 col-form-label">Biaya Telly</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="telly_charge" name="telly_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.telly_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="telly_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="trucking_charge" class="col-md-3 col-form-label">Biaya trucking</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="trucking_charge" name="trucking_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.trucking_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="trucking_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="lolo_charge" class="col-md-3 col-form-label">Biaya Lolo</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="lolo_charge" name="lolo_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.lolo_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="packing_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="thc_charge" class="col-md-3 col-form-label">Biaya thc</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="thc_charge" name="thc_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.thc_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="thc_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="do_charge" class="col-md-3 col-form-label">Biaya do</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="do_charge" name="do_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.do_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="do_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="clining_charge" class="col-md-3 col-form-label">Biaya clining</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="clining_charge" name="clining_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.clining_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="clining_charge"
                        />
                    </div>
                </div>
                <hr/>
                <div class="mb-1 row">
                    <label for="ppftz1_charge" class="col-md-3 col-form-label">Biaya Pengurusan PPFTZ 01</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="ppftz1_charge" name="ppftz1_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.ppftz1_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="ppftz1_charge"
                        />
                    </div>
                </div>

                <div class="mb-1 row">
                    <label for="ppftz2_charge" class="col-md-3 col-form-label">Biaya Pengurusan PPFTZ 02</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="ppftz2_charge" name="ppftz2_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.ppftz2_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="ppftz2_charge"
                        />
                    </div>
                </div>


                <div class="mb-1 row">
                    <label for="ppftz3_charge" class="col-md-3 col-form-label">Biaya Pengurusan PPFTZ 03</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="ppftz3_charge" name="ppftz3_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.ppftz3_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="ppftz3_charge"
                        />
                    </div>
                </div>






                <hr/>
                <div class="mb-1 row">
                    <label for="sub_total" class="col-md-3 col-form-label">Sub total</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control text-end" id="sub_total" name="sub_total" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.sub_total" aria-describedby="sub_total" disabled="true" />
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="tax_percent" class="col-md-3 col-form-label">PPn</label>
                    <div class="col-md-3 m-0">
                        <input type="text" class="form-control text-end" id="tax_percent" name="tax_percent" mask="separator.2" data-reverse="true" [(ngModel)]="DO.tax_percent" (ngModelChange)="onChangePrice($event)" aria-describedby="tax_percent" />
                    </div>
                    <label for="tax_percent" class="col-md-1 col-form-label">%</label>
                    <div class="col-md-5">
                        <input type="text" class="form-control text-end" id="tax_amount" name="tax_amount" mask="separator.0" thousandSeparator="." data-reverse="true" disabled="true" [(ngModel)]="DO.tax_amount" aria-describedby="tax_amount" />
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="sub_total_tax" class="col-md-3 col-form-label">Sub total setelah PPn</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control text-end" id="sub_total_tax"  mask="separator.0" thousandSeparator="." name="sub_total_tax"  data-reverse="true" [(ngModel)]="DO.sub_total_tax" aria-describedby="sub_total_tax" disabled="true" />
                    </div>
                </div>
             
                <!-- <div class="mb-1 row">
                    <label for="materai_charge" class="col-md-3 col-form-label">Biaya Bea Materai</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="materai_charge" name="materai_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.materai_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="materai_charge"
                        />
                    </div>
                </div>



                <div class="mb-1 row">
                    <label for="claim_charge" class="col-md-3 col-form-label">Biaya Claim Barang</label>
                    <div class="col-md-6">
                        <input type="text" class="form-control text-end" id="claim_charge" name="claim_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.claim_charge" (ngModelChange)="onChangePrice($event)" aria-describedby="claim_charge"
                        />
                    </div>
                </div> -->


                <div class="mb-1 row">
                    <label for="insurance_price" class="col-md-3 col-form-label">Nilai Asuransi </label>
                    <div class="col-md-9">
                        <div class="input-group mb-1">
                            <input type="text" class="form-control text-end" id="insurance_price" name="insurance_price" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.insurance_price" aria-describedby="insurance_price" [disabled]="!isInsurance" />
                            <div class="input-group-text">
                                <input class="form-check-input mt-0" id="isInsurance" type="checkbox" value="" aria-label="isInsurance" [(ngModel)]="isInsurance" [checked]="isInsurance">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mb-1 row">
                    <label for="insurance_charge" class="col-md-3 col-form-label">Insurance Charge</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control text-end" id="insurance_charge" name="insurance_charge" mask="separator.0" thousandSeparator="." data-reverse="true" [(ngModel)]="DO.insurance_charge" (ngModelChange)="onChangePrice($event)" [disabled]="!isInsurance"
                            aria-describedby="insurance_charge" disabled="true" />
                    </div>
                </div>
                <strong><hr/></strong>
                <div class="mb-3 row">
                    <label for="total" class="col-md-3 col-form-label">Total</label>
                    <div class="col-md-9">
                        <input type="text" class="form-control text-end" id="total"  mask="separator.0" thousandSeparator="." name="total" [(ngModel)]="DO.total" aria-describedby="total" disabled="true" />
                    </div>
                </div>
                <div class="mb-1 row">
                    <div *ngIf="loadingSave" class="spinner-grow text-primary m-1" role="status">
                        <span class="sr-only">Loading...</span>
                    </div>
            
            
                    <div class="btn-group float-end" role="group">
                        <button type="button" class="btn btn-danger ">Batal</button>
                        <button type="button" (click)="validasiShipment()" class="btn btn-primary">
                        
                        <i  *ngIf="loadingSave" class="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                        <i  *ngIf="!loadingSave" class="fas fa-save font-size-16 align-middle me-2"></i>
                        {{captionButton}}  </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="card-footer">

        
    </div>
</div>