import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};

@Injectable({
  providedIn: "root",
})
export class SaInvoiceService {
  constructor(private httpClient: HttpClient) {}

  public hitungHarga(data: any) {
    console.log(data);
    let kirim: number = 0,
      pickup: number = 0,
      packing_charge: number = 0,
      buruh_charge:number = 0,
      tools_charge:number = 0,
      telly_charge:number = 0,
    trucking_charge:number = 0,
    lolo_charge:number = 0,
    thc_charge:number = 0,
    do_charge:number = 0,
    clining_charge:number = 0,
    ppftz1_charge:number = 0,
    ppftz2_charge:number = 0,
    ppftz3_charge:number = 0,
    materai_charge:number = 0,
    claim_charge:number = 0,
      sub_total: any = 0,
      tax_percent: number = 0,
      tax_amount: any = 0,
      sub_total_tax: number = 0,
      harga_barang_asuransi: number = 0,
      harga_asuransi: number = 0,
      total: number = 0;
    kirim = parseFloat(data.charge) || 0;
    pickup = parseFloat(data.pickup_charge) || 0;
    
    tools_charge=parseFloat(data.tools_charge) || 0;
    buruh_charge=parseFloat(data.buruh_charge) || 0;
    telly_charge=parseFloat(data.telly_charge) || 0;
    trucking_charge=parseFloat(data.trucking_charge) || 0;
    lolo_charge=parseFloat(data.lolo_charge) || 0;
    thc_charge=parseFloat(data.thc_charge) || 0;
    do_charge = parseFloat(data.do_charge) || 0;
    clining_charge = parseFloat(data.clining_charge) || 0;
    ppftz1_charge = parseFloat(data.ppftz1_charge) || 0;
    ppftz2_charge = parseFloat(data.ppftz2_charge) || 0;
    ppftz3_charge = parseFloat(data.ppftz3_charge) || 0;
    materai_charge  = parseFloat(data.materai_charge) || 0;
    claim_charge  = parseFloat(data.claim_charge) || 0;

    harga_asuransi = parseFloat(data.insurance) || 0;
    sub_total =kirim+pickup+tools_charge+buruh_charge+telly_charge+trucking_charge+lolo_charge+thc_charge+do_charge+clining_charge+ppftz1_charge+ppftz2_charge+ppftz3_charge;

    tax_percent = parseFloat(data.tax_percent) || 0;

    tax_amount = (tax_percent / 100) * sub_total;

    sub_total_tax = parseFloat(sub_total) + parseFloat(tax_amount);
    
    total = sub_total_tax+harga_asuransi+claim_charge+materai_charge;

    
    return {
      kirim,
      pickup,
      tools_charge,
      buruh_charge,
      telly_charge,
      trucking_charge,
      lolo_charge,
      thc_charge,
      do_charge,
      clining_charge,
      ppftz1_charge,
      ppftz2_charge,
      ppftz3_charge,
      materai_charge,
      claim_charge,
  
      packing_charge,
      sub_total,
      tax_percent,
      tax_amount,
      sub_total_tax,
      harga_asuransi,
      total,
    };
  }

  public getSewaGudangUninvoice(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: string,
    cid?: string
  ) {
    let x;

    console.log("----tye", type);

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return cid
      ? this.httpClient
          .get(
            `${environment.apiUrl}/sa/smu/uninvoice/${companyId}/${cid}?${x}`
          )
          .toPromise()
      : this.httpClient
          .get(`${environment.apiUrl}/sa/smu/uninvoice/${companyId}?${x}`)
          .toPromise();
  }

  public getSewaGudangBySmu(id: any, type?: string) {
    let url: any;

    url = `${environment.apiUrl}/sa/smu/byid/${id}`;
    return this.httpClient.get(`${url}`).toPromise();
  }

  public getShipmentUninvoice(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: string,
    cid?: string,
    sort?:string,
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }
    if(sort){
      x += '&sort='+ sort;
    }

    return cid
      ? this.httpClient
          .get(
            `${environment.apiUrl}/sa/shipment/uninvoice/${companyId}/${cid}?${x}`
          )
          .toPromise()
      : this.httpClient
          .get(`${environment.apiUrl}/sa/shipment/uninvoice/${companyId}?${x}`)
          .toPromise();
  }

  public getShipmentPaid(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: string,
    cid?: string
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return cid
      ? this.httpClient
          .get(
            `${environment.apiUrl}/sa/shipment/paid/${companyId}/${cid}?${x}`
          )
          .toPromise()
      : this.httpClient
          .get(`${environment.apiUrl}/sa/shipment/paid/${companyId}?${x}`)
          .toPromise();
  }

  public getZeroAmount(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null,
    cid: string = null
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/shipment/zero-amount/${companyId}?${x}`)
      .toPromise();
  }
  public getShipmentCash(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null,
    cid: string = null
  ) {
    let x;

    if (term) {
      x = type
        ? `limit=${limit}&pg=${pg}&term=${term}&type=${type}`
        : `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = type
        ? `limit=${limit}&pg=${pg}&type=${type}`
        : `limit=${limit}&pg=${pg}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/shipment/cash/${companyId}?${x}`)
      .toPromise();
  }

  public getShipmentByAWB(id: any, type: string) {
    let url: any;

    if (type === "invoice") {
      url = `${environment.apiUrl}/sa/shipment/invoice/${id}`;
      return this.httpClient.get(`${url}`).toPromise();
    }
    console.log("cash");
    url = `${environment.apiUrl}/sa/shipment/cashbyid/${id}`;
    return this.httpClient.get(`${url}`).toPromise();
  }

  public getShipmentUninvoiceByAWBCustomer(companyId: string, cid: string) {
    let url: any;

    url = `${environment.apiUrl}/sa/shipment/uninvoice/${companyId}/${cid}`;
    return this.httpClient.get(`${url}`).toPromise();
  }
  public updateValidasiShipment(id: any, params: any, isCash?: boolean) {
    if (isCash) {
      return this.httpClient
        .put<any>(
          `${environment.apiUrl}/sa/shipment/cashbyid/${id}`,
          params,
          httpOptions
        )
        .toPromise();
    }

    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/sa/shipment/invoice/${id}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public getInvoice(id: any, companyId: string, type?: string) {
    let url: any;
    console.log(type);
    if (type === "SMU") {
      url = `${environment.apiUrl}/sa/invoice/wh/${companyId}/${id}`;
    } else {
      url = `${environment.apiUrl}/sa/invoice/${companyId}/${id}`;
    }
    console.log(url);
    return this.httpClient.get(`${url}`).toPromise();
  }

  public getInvoiceByNo(id: any, companyId: string) {
    let url: any;

    url = `${environment.apiUrl}/sa/invoice/${companyId}/${id}?type=NO`;
    return this.httpClient.get(`${url}`).toPromise();
  }
  public getInvoiceList(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: any,
    cid?: string,
    start_date?: any,
    end_date?: any
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    if (start_date) {
      x += `&start_date=${start_date}&end_date=${end_date}`;
    }
    if (cid) {
      x += `&contact_id=${cid}`;
    }
    if (type === "cash") {
      x += `&type=cash`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/invoice/${companyId}?${x}`)
      .toPromise();
  }

  public getInvoiceUnpaidList(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: any,
    cid?: string,
    start_date?: any,
    end_date?: any
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    if (start_date) {
      x += `&start_date=${start_date}&end_date=${end_date}`;
    }
    if (cid) {
      x += `&contact_id=${cid}`;
    }
    if (type === "cash") {
      x += `&type=cash`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/payment/${companyId}/os?${x}`)
      .toPromise();
  }


  public getInvoicePaidList(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: any,
    cid?: string,
    start_date?: any,
    end_date?: any
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    if (start_date) {
      x += `&start_date=${start_date}&end_date=${end_date}`;
    }
    if (cid) {
      x += `&contact_id=${cid}`;
    }
    if (type === "cash") {
      x += `&type=cash`;
    }

    x+='&is_paid=true';

    return this.httpClient
      .get(`${environment.apiUrl}/sa/payment/${companyId}/os?${x}`)
      .toPromise();
  }

  public saveInvoice(params: any, companyId: string) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/sa/invoice/${companyId}`,
        params,
        httpOptions
      )
      .toPromise();
  }
  public updateInvoice(params: any, companyId: string, id: any) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/sa/invoice/${companyId}/${id}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public voidInvoice(companyId: string, id: any) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/sa/invoice/${companyId}/${id}/void`,
        {},
        httpOptions
      )
      .toPromise();
  }

  public getInvoiceWHList(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: any,
    cid?: string,
    start_date?: any,
    end_date?: any
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    if (start_date) {
      x += `&start_date=${start_date}&end_date=${end_date}`;
    }
    if (cid) {
      x += `&contact_id=${cid}`;
    }
    if (type) {
      x += `&type=${type}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/invoice/wh/${companyId}?${x}`)
      .toPromise();
  }

  public saveInvoiceWH(params: any, companyId: string) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/sa/invoice/wh/${companyId}`,
        params,
        httpOptions
      )
      .toPromise();
  }
  public updateInvoiceWH(params: any, companyId: string, id: any) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/sa/invoice/wh/${companyId}/${id}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public getPayment(id: any, companyId: string) {
    let url: any;

    url = `${environment.apiUrl}/sa/payment/${companyId}/payment/${id}`;
    return this.httpClient.get(`${url}`).toPromise();
  }
  public getPaymentList(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    type?: any,
    cid?: string,
    start_date?: any,
    end_date?: any
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    if (start_date) {
      x += `&start_date=${start_date}&end_date=${end_date}`;
    }
    if (cid) {
      x += `&contact_id=${cid}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/payment/${companyId}?${x}`)
      .toPromise();
  }
  public savePayment(companyId: string, params: any) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/sa/payment/${companyId}`,
        params,
        httpOptions
      )
      .toPromise();
  }
  public updatePayment(params: any, companyId: string, id: any) {
    return this.httpClient
      .put<any>(
        `${environment.apiUrl}/sa/payment/${companyId}/${id}`,
        params,
        httpOptions
      )
      .toPromise();
  }

  public deletePaymentInvoice(
    companyId: string,
    payment_id: any,
    invoice_id: any
  ) {
    return this.httpClient
      .post<any>(
        `${environment.apiUrl}/sa/payment/${companyId}/delete-invoice/${payment_id}/${invoice_id}`,
        {},
        httpOptions
      )
      .toPromise();
  }

  public getInvoiceOsPayment(
    companyId: string,
    pg: any = 1,
    limit: any = 20,
    term: any,
    cid?: string
  ) {
    let x;

    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }

    if (cid) {
      x += `&contact_id=${cid}`;
    }

    return this.httpClient
      .get(`${environment.apiUrl}/sa/payment/${companyId}/os?${x}`)
      .toPromise();
  }
}
