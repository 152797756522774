
<div class="card">
    <div class="card-body">
        <div class="row">
            <div class="col-6">
                <h4 class="card-title mt-0">
                    Daftar Komponen Pendapatan
                    <span class="badge bg-primary rounded-pill">{{pgn.totalItems}}</span>
                </h4>
            </div>
            <div class="col-6">
                <div class="input-group mb-3">
                    <button class="btn btn-sm btn-primary" (click)="addBilling()"><i class="fa fa-list"></i> Baru</button>
                    <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData()" aria-label="Cari..." [(ngModel)]="pgn.term" aria-describedby="btn-cari" />
                    <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData()"><i class="fa fa-search"></i></button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12 mb-5" *ngIf="showBilling">
                <app-master-billing [isEdit]="isNew" [type]="type" (klikYes)="klikYes($event)"></app-master-billing>
            </div>
        </div>
    </div>
</div>