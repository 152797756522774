import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { GlobalConstants } from 'src/app/services/global';
import { SaInvoiceService } from 'src/app/services/sa/sa-invoice.service';
 

@Component({
  selector: 'app-modal-sa-invoice',
  templateUrl: './modal-sa-invoice.component.html',
  styleUrls: ['./modal-sa-invoice.component.scss']
})
export class ModalSaInvoiceComponent implements OnInit {
  @Input() companyId:string;
  @Input() contactId:string;
  dataList:any;
  global={...GlobalConstants.x}
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private invoiceService:SaInvoiceService,
    private toast:Toaster,
    private authService: AuthenticationService
  ) { }

  ngOnInit(): void {
    this.loadPage(1);
    console.log(this.companyId)
  }
  pilihData(data:any){
    this.activeModal.close(data.invoice_no);
  }
  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData();
    }
  }

  async getData(){
    try {
      const res: any  = await this.invoiceService.getInvoiceOsPayment(this.companyId, this.pgn.currentPage,this.pgn.limit,this.pgn.term,this.contactId);
      console.log(res)
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
        // this.cu=res.current;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  closeModal(sendData) {

    this.activeModal.close({
     
      data:sendData
    });
  }

}
