<div class="modal-header">
    <h5 class="modal-title mt-0">{{judul}}</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    <div *ngIf="showInfo">
        <div class="row">
          <div class="col-6">
          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData(type,cid,hub)" aria-label="Cari..." [(ngModel)]="pgn.term"  aria-describedby="btn-cari">
              <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData(type,cid,hub)"><i class="fa fa-search"></i></button>
            </div>
          </div>
          <div class="col-12">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead style="position: sticky;top: 0">
                  <tr>
                    <th>#</th>
                    <th *ngIf="isTrx"></th>
                    <th>No Resi</th>
                    <th>Tanggal</th>
                    <th>Pengirim</th>
                    <th>Penerima</th>
                    <th>Asal-Tujuan</th>
                  
                    <th>Status</th>\
                    <th>Total</th>
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let n of dataList; let i = index">
                    <td style="width: 80px;" class="text-end">{{ ((pgn.currentPage-1)*pgn.limit)+(i+1) }}</td>
                    <td *ngIf="isTrx"><a href="javascript:void(0)" (click)="pilihData(n)" ngbTooltip="Pilih {{n.shipment_awbm}}">Pilih</a></td>
                    <td *ngIf="!isSelect"><a href="javascript:void(0)" (click)="showData(n)" ngbTooltip="info Lengkap">  {{n.shipment_awbm}}</a></td>
                    <td *ngIf="isSelect"><a href="javascript:void(0)" (click)="closeModal(n)" ngbTooltip="info Lengkap">  {{n.shipment_awbm}}</a></td>
                    <td >{{n.shipment_date | date : "dd-MM-yyyy" }}</td>
                    <td>{{n.partner_name}}</td>
                    <td>{{n.receiver_name}}</td>
                    <td>{{n.originname}} - {{n.destinationname}}</td>
                
                    <td >{{n.last_status_remark}}</td>
                    <td class="text-end">{{n.total|number}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <ngb-pagination  class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage" [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage" [rotate]="true"></ngb-pagination>
          </div>
        </div>
    </div>
    <div *ngIf="showDetail" class="container">
        <p class="h2" *ngIf="dataDetail.is_cash"><i class="fa fa-money text-primary"></i> Detail Shipment</p>
        <hr/>
        <div class="row">
          <div class="col">
            <p class="h6">No Resi</p>
            <p class="text-muted">{{dataDetail.shipment_awbm}}</p>
          </div>
          <div class="col">
            <p class="h6">Tanggal Pengiriman</p>
            <p class="text-muted">{{dataDetail.shipment_date  | date : "dd-MM-yyyy" }}</p>
          </div>
          <div class="col">
            <p class="h6">Referensi</p>
            <p class="text-muted">{{dataDetail.shipment_do}}</p>
          </div>
          <div class="col">
            <p class="h6">Moda</p>
            <p class="text-muted"> <i class ="fi text-primary" [ngClass]="{'flaticon-airplane-frontal-view': dataDetail.moda_id === 'A', 'flaticon-delivery-truck' :  dataDetail.moda_id === 'L' ,'flaticon-sea-ship': dataDetail.moda_id === 'S'}"> </i> {{dataDetail.moda_id=='A'?'AIR':dataDetail.moda_id=='L'?'LAND':'SEA' }}</p>
          </div>
          <div class="col">
            <p class="h6">Service</p>
            <p class="text-muted">{{dataDetail.service_id}}</p>
          </div>
          <div class="col">
            <p class="h6">UOM</p>
            <p class="text-muted">{{dataDetail.weight}} - {{dataDetail.uom_id}}</p>
          </div>
        </div>
        <hr/>
        <div class="row">
            <div class="col">
              <p class="h6">Pengirim</p>
                <div class="row text-muted">
                  <div class="col-3">Nama</div>
                  <div class="col-9">{{dataDetail.partner_id}}-{{dataDetail.partner_name}}</div>
                  <div class="col-3">Alamat</div>
                  <div class="col-9">{{dataDetail.partner_address1}}</div>
                  <div class="col-3">Phone</div>
                  <div class="col-9">{{dataDetail.partner_phone1}}</div>
                  <div class="col-3">Asal</div>
                  <div class="col-9">{{dataDetail.origin}} - {{dataDetail.originname}}</div>
                </div>
            </div>
            <div class="col">
              <p class="h6">Penerima</p>
                <div class="row text-muted">
                  <div class="col-3">Nama</div>
                  <div class="col-9">{{dataDetail.receiver_name}}</div>
                  <div class="col-3">Alamat</div>
                  <div class="col-9">{{dataDetail.receiver_address1}}</div>
                  <div class="col-3">Phone</div>
                  <div class="col-9">{{dataDetail.receiver_phone1}}</div>
                  <div class="col-3">Tujuan</div>
                  <div class="col-9">{{dataDetail.destination}} - {{dataDetail.destinationname}}</div>
                </div>
            </div>
        </div>
        <hr/>
        <div class="row">
          <div class="col">
              <div class="row text-muted">
                <div class="col-3">Pickup By</div>
                <div class="col-9">{{dataDetail.pickup_by}}</div>
                <div class="col-3">Sales By</div>
                <div class="col-9">{{dataDetail.sales_by}}</div>
                
              </div>
          </div>
          <div class="col">
            <div class="row text-muted">
              <div class="col-3">Intruksi</div>
              <div class="col-9">{{dataDetail.specialinst}}</div>
              <div class="col-3">Item Description</div>
              <div class="col-9">{{dataDetail.itemdesc}}</div>
              <div class="col-3">Status</div>
              <div class="col-9"><span class="badge badge-pill badge-soft-info font-size-11" [ngClass]=" { 'badge-soft-danger': dataDetail.last_status === 'PICKUP',
                'badge-soft-warning':dataDetail.last_status === 'MANIFEST' }">{{dataDetail.last_status_remark}}</span></div>
            </div>

          </div>
        </div>
    </div>
</div>
<div class="modal-footer">
  <button type="button" *ngIf="showDetail" class="btn btn-primary" data-dismiss="modal" (click)="hideData()"><i class="fa fa-backward"></i>Kembali</button>   
    <button type="button" *ngIf="showInfo" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>