import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import {NgbDate, NgbCalendar, NgbDateParserFormatter} from '@ng-bootstrap/ng-bootstrap';
import { EventService } from 'src/app/services/event.service';
const globalDate = new Date();
@Component({
  selector: 'app-pilih-periode',
  templateUrl: './pilih-periode.component.html',
  styleUrls: ['./pilih-periode.component.scss']
})
export class PilihPeriodeComponent implements OnInit {

  @Input() title:string;
  @Output() onChangeFilter:EventEmitter<any>= new EventEmitter();
  hoveredDate: NgbDate | null = null;
  fromDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1,globalDate.getHours(),globalDate.getMinutes());
  toDate:any =  new Date(globalDate.getFullYear(), globalDate.getMonth()+1, 0,globalDate.getHours(),globalDate.getMinutes());
  fromMonth:any= new Date(globalDate.getFullYear(),0, 1)
  toMonth:any= new Date(
    globalDate.getFullYear(),
    11,
    31
  );
  fromYear:any= new Date(2020,0, 1)
  toYear:any= new Date(
    globalDate.getFullYear(),
    11,
    31
  );
  isShowFilter:boolean=true;
  isActive:string="harian";
  isShowDate:boolean=true;
  isShowMonth:boolean=false;
  isShowYear:boolean=false;
  startDate: any = new Date(globalDate.getFullYear(), globalDate.getMonth(), 1);
  endDate: any = new Date(
    globalDate.getFullYear(),
    globalDate.getMonth() + 1,
    0
  );
  constructor(public formatter: NgbDateParserFormatter) {
   }
  ngOnInit(): void {
    this.doFilter();
  }
  showFilter(){
    this.isShowFilter=!this.isShowFilter
  }
  onChangePeriods(e:any){
    this.isActive=e;
    if (e==='harian'){
      this.isShowDate=true;
      this.isShowMonth=false
      this.isShowYear=false;
    }
    if (e==='bulanan'){
      this.isShowDate=false;
      this.isShowMonth=true;
      this.isShowYear=false;
    }
    if (e==='tahunan'){
      this.isShowDate=false;
      this.isShowMonth=false;
      this.isShowYear=true;
    }
  }
  onChangeDate(newDate, field: string) {
    if (field=='tgl_start'){
      this.fromDate=new Date(newDate)
    }
    if (field=='tgl_end'){
      this.toDate=new Date(newDate)
    }
  }
  onChangeMonth(newDate, field: string) {
    if (field=='tgl_start'){
      this.fromMonth=new Date(newDate)
    }
    if (field=='tgl_end'){
      this.toMonth=new Date(newDate)
    }
  }
  onChangeYear(newDate, field: string) {
    if (field=='tgl_start'){
      this.fromYear=new Date(newDate)
    }
    if (field=='tgl_end'){
      this.toYear=new Date(newDate)
    }
  }
  doFilter(){
    if (this.isActive==='harian') {
      console.log(this.fromDate.toJSON())
      let x1 = new Date(this.fromDate); //this.fromDate.toJSON().slice(0, 10).split("-");
      let x2 = new Date(this.toDate) //this.toDate.toJSON().slice(0, 10).split("-")
      // console.log(x2);
      // console.log(x1);
      // console.log({year:x1.getFullYear() ,month:x1.getMonth()+1,day:x1.getDate()})
      this.onChangeFilter.emit({
        fromDate : {year:x1.getFullYear() ,month:x1.getMonth()+1,day:x1.getDate()},
        endDate: {year:x2.getFullYear() ,month:x2.getMonth()+1,day:x2.getDate()},
        type:this.isActive
      });
    }

    if (this.isActive==='bulanan') {
      this.onChangeFilter.emit({
        fromDate: {year: this.fromMonth.getFullYear(),month: this.fromMonth.getMonth()+1,day:1} ,
        endDate: {year:this.toMonth.getFullYear(),month:this.toMonth.getMonth()+1,day:1} ,
        type:this.isActive
      });
    }
    if (this.isActive==='tahunan') {
      this.onChangeFilter.emit({
        fromDate: {year: this.fromYear.getFullYear(),month: this.fromYear.getMonth()+1,day:1} ,
        endDate: {year:this.toYear.getFullYear(),toYear:this.toYear.getMonth()+1,day:1} ,
        type:this.isActive
      });
    }
  }

}
