import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Toaster } from 'ngx-toast-notifications';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { SaCompanyService } from 'src/app/services/sa/sa-company.service';

@Component({
  selector: 'app-select-company',
  templateUrl: './select-company.component.html',
  styleUrls: ['./select-company.component.scss']
})
export class SelectCompanyComponent implements OnInit {

  dataList:any=[];
  userValue:any;
  cabangAktif:any;
  @Input() CID:string;
  @Output() gantiCabang: EventEmitter<any> = new EventEmitter();

  constructor(
    private saCompany:SaCompanyService,
    private toaster:Toaster,
    private authService: AuthenticationService,

  ) { 
   
  }


  cariDataPerusahaan(company_id:string){
    return this.dataList.find(i => i.company_id === company_id)
  }

  pilihCabang(company_id:string){

    this.cabangAktif=this.cariDataPerusahaan(company_id)
    this.gantiCabang.emit(this.cabangAktif)
  }
  async ngOnInit() {
    this.userValue = this.authService.currentUserValue;
    await this.getCompanyBranch();
    this.cabangAktif=this.cariDataPerusahaan(this.userValue.company_id)
  }

  async getCompanyBranch(){
    const res:any = await this.saCompany.getBranchList();
    if (res.success){
        if(this.userValue.role === "cs"){
          this.dataList=res.data.filter((f)=>f.company_id === this.userValue.company_id);
        }else{
          this.dataList=res.data;
        }
    }else{
      // this.toaster.open({type:'danger',text:'Error'})
    }

  }
  
  changeBranch(){
    this.gantiCabang.emit(this.cabangAktif)
  }



}
