import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';

@Component({
  selector: 'app-modal-manifest-shipment',
  templateUrl: './modal-manifest-shipment.component.html',
  styleUrls: ['./modal-manifest-shipment.component.scss']
})
  export class ModalManifestShipmentComponent implements OnInit {


    @Input() manifestNo:string;
    dataList:any;
    isLoading:boolean=false;
    countData:{
      qty:0,
      berat:0,
      total:0
    }
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) { }

  ngOnInit(): void {
  this.getData();
  }


  showDataDetail(data:any):void {

  }


  async getData(){
    try {
      const res: any  = await this.apiService.MANIFEST.manifestById(this.manifestNo);
      console.log(res);
      
      
      if(res && res.success==true){
        this.dataList = res.data;
        const qty = res.data.detail_manifest.reduce((x, current) => {
          return x + current.shipment_detail.qty;
        }, 0);
        const berat = res.data.detail_manifest.reduce((x, current) => {
          return x + current.shipment_detail.weight;
        }, 0);
        const total = res.data.detail_manifest.reduce((x, current) => {
          return x + current.shipment_detail.total;
        }, 0);

        this.countData={
          qty,
          berat,
          total
        }
       
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }

  }
  closeModal(sendData) {
    this.activeModal.close(sendData);
  }

}
