import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
const httpOptions = {
  headers: new HttpHeaders({ Accept: "application/json" }),
};
@Injectable({
  providedIn: 'root'
})
export class SaContactService {

  constructor(private httpClient: HttpClient) {}
  public getContact(
    pg: any = 1,
    limit: any = 20,
    term: any,
    type: string = null,
    companyId:string
  ) {
    let x;
    if (term) {
      x = `limit=${limit}&pg=${pg}&term=${term}`;
    } else {
      x = `limit=${limit}&pg=${pg}`;
    }
    return this.httpClient
      .get(`${environment.apiUrl}/sa/contact/${type}/${companyId}?${x}`)
      .toPromise();
  }

  public getUserList(companyId:string){

 return this.httpClient
    .get(`${environment.apiUrl}/sa/user/${companyId}`)
    .toPromise();

  }


  public userDetail(id:any){
        return this.httpClient
      .get(`${environment.apiUrl}/sa/user/${id}/detail`)
      .toPromise();
    
    }

  public updatePassword(id:any, password:any){
    return this.httpClient
    .post<any>(`${environment.apiUrl}/sa/user/${id}/setpassword`, {
      password:password
    }, httpOptions)
    .toPromise();

  }
  public blockUser(id:any){
    return this.httpClient
    .post<any>(`${environment.apiUrl}/sa/user/${id}/block`, {}, httpOptions)
    .toPromise();
  }
  public unblockUser(id:any){
    return this.httpClient
    .post<any>(`${environment.apiUrl}/sa/user/${id}/unblock`, {}, httpOptions)
    .toPromise();
  }
}
