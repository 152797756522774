<div class="table-responsive">
    <table class="table mb-0">
        <thead class="thead-dark">
    
            <tr>
                <th style="width: 70px;">#</th>
                <th>No Invoice</th>
                <th>Tanggal Print</th>
                <th>Tanggal Terima</th>
                <th>Tanggal JT</th>
                <th>Nama</th>
    
                <th>Aging (Hari)</th>
                <th>Total</th>
    
                <th>Paid</th>
                <th>Balance</th>
    
                <th style="width:100px;">Actions</th>
            </tr>
        </thead>
        <tbody *ngFor="let n of dataList;let i = index">
            <tr>
                <td class="text-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="!n.showDetail"
                            (click)="showDataDetail(i)" ngbTooltip="Lihat Detail">{{i+1}} <i
                                class="bx bx-chevrons-down"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-primary" *ngIf="n.showDetail"
                            (click)="showDataDetail(i)" ngbTooltip="Tutup Detail">{{i+1}} <i
                                class="bx bx-chevrons-up"></i></button>
                    </div>
                </td>
                <td>{{n.invoice_no}}</td>
                <td>{{n.invoice_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.received_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.invoice_due_date| date : "dd-MM-yyyy" }}</td>
                <td>{{n.contact_name}}({{n.contact_id}})</td>
    
                <td></td>
                <td class="text-end">{{n.amount|number}}</td>
                <td class="text-end">{{n.paid|number}}</td>
                <td class="text-end">{{n.balance|number}}</td>
                <td class="text-center">
                    <div class="btn-group" role="group" aria-label="Basic example">
                        <a  href="/sa-invoice/sewagudang/edit/{{n.id}}" role="button" class="btn btn-sm btn-outline-secondary"
                            *ngIf="!n.is_paid  && n.invoice_type==='SMUWH-O'" ngbTooltip="Edit Invoice"><i
                                class="bx bx-edit-alt"></i></a>
                                <a  href="/sa-invoice/sewagudanginbound/edit/{{n.id}}" role="button" class="btn btn-sm btn-outline-secondary"
                                *ngIf="!n.is_paid   && n.invoice_type==='SMUWH-I'" ngbTooltip="Edit Invoice"><i
                                    class="bx bx-edit-alt"></i></a>
                        <button type="button" class="btn btn-sm btn-outline-success" ngbTooltip="Pembayaran "
                            (click)="createPayment(n.invoice_id)"><i class="bx bx-money"></i></button>
                        <button type="button" class="btn btn-sm btn-outline-info" ngbTooltip="Cetak Invoice "
                            (click)="openPrintModal('WH',n.id)"><i class="bx bx-printer"></i></button>
    
                    </div>
                </td>
            </tr>
            <tr *ngIf="n.showDetail">
                <td colspan="12">
    
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="card">
                                <div class="card-body">
                                 
                                    <div class="row">
                                        <div class="col-6">
                                            <address>
                                                <strong>Kepada :</strong><br>
                                                {{n.contact_name}}<br>
                                                {{n.contact_bill_address}}
                                            </address>
                                        </div>
                                        <div class="col-6 text-sm-end">
                                            <address>
                                                <strong>Informasi Internal </strong><br>
                                                Cetak Oleh : {{n.print_by}}<br>
                                              
                                            </address>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-6 mt-3">
                                            <address>
                                                <strong>Pembayaran :</strong><br>
                                               <br>
                                               
                                            </address>
                                        </div>
                                        <div class="col-6 mt-3 text-sm-end">
                                            <address>
                                                <strong>Tanggal Invoice:</strong><br>
                                                {{n.invoice_date| date : "dd-MM-yyyy" }}<br><br>
                                            </address>
                                        </div>
                                    </div>
                                    <div class="py-2 mt-3">
                                        <h3 class="font-size-15 fw-bold">Detail</h3>
                                    </div>
                                    <div class="table-responsive">
                                        <table class="table">
                                            <thead class="thead-dark">
                                                <tr>
                                                    <th>#</th>
                                                    <th scope="col">No SMU</th>
                                        <th scope="col">Jenis</th>
                                        <th scope="col">Tanggal</th>
                                        
                                        <th scope="col">Koli</th>
                                        <th scope="col">Berat</th>
                                        <th scope="col">Asal</th>
                                        <th scope="col">Tujuan</th>
                                        <th scope="col">Item</th>
                                        <th scope="col">Total</th>
                                        <th scope="col">Admin</th>
                                        <th scope="col">Hapus</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let x of n.invoice_detail_wh;let y = index">
                                                    <td>{{y+1}}
                                                       
                                                    </td>
                                                    <td>
                                                         {{x.smu_detail.smu_no}}   
                                                    </td>
                                                    <td>{{x.smu_detail.smu_type}}</td>
                                                    <td>{{x.smu_detail.smu_date | date : "dd-MM-yyyy" }}</td>
                                                    <td>{{x.smu_detail.qty}}</td>
                                                                                    <td>{{x.smu_detail.weight}}</td>
                                                                                    <td>{{x.smu_detail.host_origin}}</td>
                                                                                    <td>{{x.smu_detail.host_destination}}</td>
                                                                                    <td>{{x.smu_detail.description}}</td>
                                                    <td class="text-end">{{x.smu_detail.price_wh|number}}</td>
                                                    <td class="text-end">{{x.smu_detail.admin_wh|number}}</td>
                                                </tr>
                                               
                                            </tbody>
                                        </table>
                                    </div>
                                    <!-- <div class="d-print-none">
                                        <div class="float-end">
                                            <a href="javascript:window.print()" class="btn btn-success"><i
                                                    class="fa fa-print"></i></a>
                                            <a href="javascript: void(0);" class="btn btn-primary w-md ms-1">Send</a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </td>
            </tr>
        </tbody>
    </table>
</div>
