import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Lightbox } from 'ngx-lightbox';
import { ModalPrintComponent } from 'src/app/modals/modal-print/modal-print.component';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-sa-shipment-main',
  templateUrl: './sa-shipment-main.component.html',
  styleUrls: ['./sa-shipment-main.component.scss']
})
export class SaShipmentMainComponent implements OnInit {


  @Input() dataList:any;
  @Input() type:string;
  @Input() count:number;
  imgUrl: any = environment.publicUrl + "/files/uploads/";
  
  constructor(
    private router:Router,
    private modal:NgbModal,
    private lightbox: Lightbox
  ) { }

  ngOnInit(): void {
  }

  showDataDetail(i:any){
    if (!this.dataList[i]['showDetail']){
      this.dataList[i]['showDetail']=true;
    }else{
      this.dataList[i]['showDetail']=false;
    }
  }
  openPrintModal(typePrint:string,id:string){
    const modalRef = this.modal.open(ModalPrintComponent, {
      // scrollable: true,
      size:'xl',
    }); 
    modalRef.componentInstance.fromParent ={
      format:typePrint,
      id,
      report:'RESI'
    };
    modalRef.result.then((result) => {
    }, (reason) => {
      console.log(reason);
    });
  }
  openImage(data: any): void {
    // open lightbox

    let Albums = [];

    if (data && data.pic1)
      Albums.push({
        src: `${this.imgUrl}${data?.pic1}`,
        caption: `Photo 1 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic1}`,
      });
    if (data && data.pic2)
      Albums.push({
        src: `${this.imgUrl}${data?.pic2}`,
        caption: `Photo 2 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic2}`,
      });
    if (data && data.pic3)
      Albums.push({
        src: `${this.imgUrl}${data?.pic3}`,
        caption: `Photo 3 - ${data?.description}`,
        thumb: `${this.imgUrl}${data?.pic3}`,
      });

    this.lightbox.open(Albums, 0, {
      wrapAround: true,
      showImageNumberLabel: true,
    });
  }
}
