import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { FormBuilder, FormControl, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ModalHubComponent } from 'src/app/modals/modal-hub/modal-hub.component';
import { ModalQuestionsComponent } from 'src/app/modals/modal-questions/modal-questions.component';
import { ModalShipmentComponent } from 'src/app/modals/modal-shipment/modal-shipment.component';
import { ApiService } from 'src/app/services/api.service';
import { GlobalConstants } from "src/app/services/global";
import { MessageService } from "src/app/services/message.service";
@Component({
  selector: 'app-master-cabang',
  templateUrl: './master-cabang.component.html',
  styleUrls: ['./master-cabang.component.scss']
})
export class MasterCabangComponent implements OnInit {
  @Input() isEdit: boolean;
  @Input() branchData: any;
  validateForm!: FormGroup;
  @Output() klikYes: EventEmitter<any> = new EventEmitter();
  submit:boolean=false;
  constructor(
    private toaster: Toaster,
    private apiService: ApiService,
    private fb: FormBuilder,
    private modal: NgbModal,
    private message:MessageService
  ) {
    this.validateForm = this.fb.group({
      company_id: [{ value: null, disabled: true }],
      company_name: [null, [Validators.required]],
      alias: [null,[Validators.required]],
      email: [null,],
      pic: [null,],
      phone1: [null],
      phone2: [null],
      address1: [null, [Validators.required]],
      city_code: [{ value: null, disabled: true }, [Validators.required]],
      city_name: [{ value: null, disabled: true }],
      invoice_approve:[{ value: null}]
    });
   }
  ngOnInit(): void {
    console.log('-----naster cabvang',this.isEdit)
    if (this.isEdit) {
      console.log('-->>>>>>>> edit',this.branchData);
      this.fillFormData(this.branchData);
    }
  }
  fillFormData(data: any) {
    this.validateForm.controls["company_id"].setValue(data.company_id);
    this.validateForm.controls["company_name"].setValue(data.company_name);
    this.validateForm.controls["email"].setValue(data.email);
    this.validateForm.controls["alias"].setValue(data.alias);
    this.validateForm.controls["pic"].setValue(data.pic);
    this.validateForm.controls["phone1"].setValue(data.phone1);
    this.validateForm.controls["phone2"].setValue(data.phone2);
    this.validateForm.controls["address1"].setValue(data.address1);
    this.validateForm.controls["city_code"].setValue(data.city_code);
    this.validateForm.controls["city_name"].setValue(data.city_name);
    this.validateForm.controls["invoice_approve"].setValue(data.invoice_approve);
  }
  openModalHub() {
    const modalRef = this.modal.open(ModalHubComponent, {
      scrollable: true,
      size: 'lg',
      keyboard: false,
      backdrop: 'static'
    });
    modalRef.result.then((r) => {
      if (r) {
        this.validateForm.controls["city_code"].setValue(r.hub_id);
        this.validateForm.controls["city_name"].setValue(r.hub_name);
      }
    }, (x) => {
    })
  }
  async submitForm(){
    this.submit = true;
    console.log('---->>>>>>submit')
    let prm ={
      ...this.validateForm.value,
      city_code:this.validateForm.controls['city_code'].value,
      city_name:this.validateForm.controls['city_name'].value
        }
    // if (this.validateForm.invalid) {
    //   return this.toast.open({ type: "danger", text: "Mohon isi field yang di perlukan" });
    // }
    if (this.isEdit){
        const res:any = await this.apiService.COMPANY.branchUpdate(prm,this.validateForm.controls['company_id'].value);
        if (res.success){
          this.toaster.open({ type: "success", text: "Cabang berhasil di update" });
          this.klikYes.emit({s:true})
        }else{
          return this.toaster.open({ type: "danger", text: res.error });
        }
    }else{
      const res:any = await this.apiService.COMPANY.branchSave(prm);
      console.log('--->>>>res',res);
        if (res.success){
          this.toaster.open({ type: "success", text: "Contact berhasil di simpan" });
          this.klikYes.emit({s:true})
        }else{
          return this.message.showError(res.err)
          // return this.toaster.open({ type: "danger", text: res.error });
        }
    }
  }
  get form() {
    return this.validateForm.controls;
  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  batalForm(){
    this.klikYes.emit({s:false})
  }
}
