import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ModalQuestionsComponent } from 'src/app/modals/modal-questions/modal-questions.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthenticationService } from 'src/app/services/authentication.service';
@Component({
  selector: 'app-upload-button',
  templateUrl: './upload-button.component.html',
  styleUrls: ['./upload-button.component.scss']
})
export class UploadButtonComponent implements OnInit {
  @Input() cid:string;
  @Input() type:string='customer';
  @Output() resultUpload:EventEmitter<any>= new EventEmitter();
  fileResult:any;
  result:any={};
  loading:boolean=false;
  userValue:any;
  constructor(
    private toast:Toaster,
    private modal:NgbModal,
    private apiService:ApiService,
    private auth:AuthenticationService
  ) {}
  ngOnInit(): void {
    this.userValue = this.auth.currentUserValue;
  }
  onFileChange(event:any) {
    const reader = new FileReader();
    if(event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.fileResult=file;
      reader.readAsDataURL(file);
      reader.onload = () => {
        this.result={
          image:reader.result as string
        }
        this.resultUpload.emit(this.result);
        // this.imageSrc = reader.result as string;
        // this.myForm.patchValue({
        //   fileSource: reader.result
        // });
      };
    }
  }

  async saveUploadFile(){
    try {
      this.loading = true;
      const payload = new FormData();
      console.log('type',this.type);
      payload.append('file', this.fileResult)
      let res:any;
      if (this.type=='customer'){
        res = await this.apiService.updateContactPic(this.cid, payload,this.type);
 
      } else if (this.type=='companyho'){
        res = await this.apiService.updateHOPic(payload);
      } else {
        res= await this.apiService.uploadFileShipment(payload);
        
        
      }

      console.log(res);
      if (res){
        this.result.data=res;
      }
      this.resultUpload.emit(this.result);
      
      
      
      this.loading = false;
    } catch (error) {
      this.loading = false;
      this.toast.open({type:'danger',text:'Oops.. sedang terjadi kesalahan.'})
      
    }
  }
  submitFile(){
    const modalRef = this.modal.open(ModalQuestionsComponent, {
      // scrollable: true,
      size:'sm',
      keyboard: false,
      backdrop: 'static'
    }); 
    modalRef.componentInstance.title ='Upload Gambar';
    modalRef.componentInstance.content ='Upload file tersebut?';
    modalRef.result.then((result) => {
      if (result){
        return this.saveUploadFile();
      }else{
        this.toast.open({type:'danger',text:'Upload file di batalkan'})
      }

    }, (reason) => {
      console.log('res',reason)
    });
  }


}
