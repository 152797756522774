import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { ApiService } from 'src/app/services/api.service';
@Component({
  selector: 'app-modal-cabang',
  templateUrl: './modal-cabang.component.html',
  styleUrls: ['./modal-cabang.component.scss']
})
export class ModalCabangComponent implements OnInit {
  @Input() fromParent;
  dataList:any;
  constructor(
    public activeModal:NgbActiveModal,
    private apiService:ApiService,
    private toast:Toaster
  ) { }
  ngOnInit(): void {
    this.getData();
  }
  async getData(){
    try {
      const res: any  = await this.apiService.COMPANY.branch(this.fromParent.hub);
      if(res && res.success==true){
        this.dataList = res.data;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  closeModal(sendData) {
    console.log(sendData);
    this.activeModal.close({
      index:this.fromParent.index,
      data:sendData
    });
  }
}
