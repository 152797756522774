<div class="modal-header">
    <h5 class="modal-title mt-0">Hub</h5>
    <button type="button" class="btn-close" data-dismiss="modal" (click)="closeModal('dismiss')"><span aria-hidden="true"></span>
    </button>
</div>
<div class="modal-body">
    
        <div class="row">
          <div class="col-6">
          </div>
          <div class="col-6">
            <div class="input-group mb-3">
              <input type="text" class="form-control" placeholder="Cari..." (keyup.enter)="getData()" aria-label="Cari..." [(ngModel)]="pgn.term"  aria-describedby="btn-cari">
              <button class="btn btn-outline-primary" type="button" id="btn-cari" (click)="getData()"><i class="fa fa-search"></i></button>
            </div>
          </div>
          <div class="col-12">
            <div class="table-responsive">
              <table class="table mb-0">
                <thead style="position: sticky;top: 0">
                  <tr>
                    <th>#</th>
                    <th>Kode Hub</th>
                    <th>Nama</th>
                    <th>Provinsi</th>
                    
                    
                  </tr>
                </thead>
                <tbody>
                  <tr  *ngFor="let n of dataList; let i = index">
                    <td style="width: 50px;" class="text-end">{{ ((pgn.currentPage-1)*pgn.limit)+(i+1) }}</td>
                    <td class="text-center"><a href="javascript:void(0)" (click)="closeModal(n)" ngbTooltip="Pilih {{n.hub_id}}">  {{n.hub_id}}</a></td>
                    <td class="text-start">{{n.hub_name}}</td>
                    <td class="text-start">{{n.province.province_name}}</td>
                    
                    
                  </tr>
                </tbody>
              </table>
            </div>
            <ngb-pagination  class="d-flex justify-content-center" (pageChange)="loadPage($event)" [(page)]="pgn.currentPage"
            [collectionSize]="pgn.totalItems" [maxSize]="5" [pageSize]="pgn.itemsPerPage"
            [rotate]="true"></ngb-pagination>
          </div>
        </div>
     
  
</div>
<div class="modal-footer">
  
    <button type="button" class="btn btn-secondary" data-dismiss="modal" (click)="closeModal('close')">Close</button>   
</div>