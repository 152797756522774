import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Toaster } from 'ngx-toast-notifications';
import { GlobalConstants } from 'src/app/services/global';
import { ProvinceService } from 'src/app/services/master/province.service';

@Component({
  selector: 'app-modals-city',
  templateUrl: './modals-city.component.html',
  styleUrls: ['./modals-city.component.scss']
})
export class ModalsCityComponent implements OnInit {
  dataList:any;
  global={...GlobalConstants.x}
  pgn={
    totalItems:0,
    itemsPerPage:0,
    limit:this.global.limitData,
    currentPage:1,
    prevPage:0,
    term:null
  };
  constructor(
    public activeModal:NgbActiveModal,
    private provinsiService:ProvinceService,
    private toast:Toaster
  ) { }

  ngOnInit(): void {
    this.loadPage(1)
  }

  loadPage(page: any) {
    if (page !== this.pgn.prevPage) {
      this.pgn.prevPage = page;
      this.pgn.currentPage=page;
      this.getData();
    }
  }

  async getData(){
    try {
      const res: any  = await this.provinsiService.getCity(this.pgn.currentPage,this.pgn.limit,this.pgn.term);
      console.log(res)
      if(res && res.success==true){
        this.dataList = res.data;
        this.pgn.totalItems=res.total;
        this.pgn.itemsPerPage=res.limit;
      }
      } catch (error) {
        this.toast.open({type:'danger',text:error})
      }
  }
  closeModal(sendData) {
    this.activeModal.close({
      data:sendData
    });
  }

}
